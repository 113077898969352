<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <h1 class="text-medium">
        Hasil Produksi
      </h1>
      <div class="uk-margin-top">
        <div class="uk-overflow-auto">
          <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider">
            <thead class="thead-table-paranje">
              <tr>
                <th
                  class="table-header uk-table-small uk-width-auto"
                  rowspan="2"
                >
                  <span class="uk-text-bold">No. </span>
                </th>
                <th
                  class="table-header uk-table-expand uk-width-auto"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Sales Shipment </span>
                </th>
                <th
                  class="table-header uk-table-auto uk-width-auto"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Tanggal</span>
                </th>
                <th
                  class="table-header uk-table-auto uk-width-auto"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Usia Panen</span>
                </th>
                <th
                  class="table-header uk-table-expand uk-width-auto"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Nama Pembeli</span>
                </th>
                <th
                  class="table-header uk-table-expand uk-text-center"
                  colspan="2"
                >
                  <span class="uk-text-bold">Jumlah</span>
                </th>
                <th
                  style="width: 135px"
                  class="table-header uk-text-center uk-width-auto"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Rata/kg</span>
                </th>
                <th
                  style="width: 135px"
                  class="table-header uk-text-center  uk-table-expand uk-width-auto"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Harga</span>
                </th>
                <th
                  style="width: 135px"
                  class="table-header uk-text-center  uk-table-expand uk-width-auto"
                  rowspan="2"
                >
                  <span class="uk-text-bold">Total Pendapatan Kotor</span>
                </th>
              </tr>
              <tr>
                <td class="act-std uk-text-center">
                  Ekor
                </td>
                <td class="act-std uk-text-center">
                  Kg
                </td>
              </tr>
            </thead>
            <template v-if="!isLoading">
              <tbody
                v-if="dataList.length > 0"
                style="border: 1px solid #e5e5e5"
              >
                <tr
                  v-for="(item, i) in dataList"
                  :key="i"
                  style="cursor: pointer"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.code || "-" }}</td>
                  <td>{{ formatDate(item.harvest_date) || "-" }}</td>
                  <td>{{ !item.harvest_age == null ? "-" : item.harvest_age }}</td>
                  <td>{{ item.customer_name || "-" }}</td>
                  <td class="uk-text-center">
                    {{ thousandSeparator(item.number_of_tail) || 0 }}
                  </td>
                  <td class="uk-text-center">
                    {{ thousandSeparatorFloat(item.total_weight, 1) || 0 }}
                  </td>
                  <td class="uk-text-center">
                    {{ roundedToFloat3Decimals(item.average_weight, 3) || 0 }}
                  </td>
                  <td class="uk-text-center">
                    {{ setCurrency(item.price_tail_contract) || 0 }}
                  </td>
                  <td class="uk-text-center">
                    {{ setCurrency(item.total_gross_income) || 0 }}
                  </td>
                </tr>
                <tr>
                  <th
                    class="table-header uk-table-small uk-width-auto uk-text-right"
                    colspan="5"
                  >
                    <span class="uk-text-bold">Total Hasil Produksi</span>
                  </th>
                  <th class="table-header uk-table-small uk-width-auto">
                    <span class="uk-text-bold">
                      <!-- Summary from number_of_tail -->
                      {{ dataList.length > 1 ? thousandSeparator(summaryOneTarget(dataList, "number_of_tail")) : dataList.length > 0 ? thousandSeparator(dataList[0].number_of_tail) : "-" }} ekor
                    </span>
                  </th>
                  <th class="table-header uk-table-small uk-width-auto">
                    <span class="uk-text-bold">
                      <!-- Summary from total_weight -->
                      {{ dataList.length > 1 ? thousandSeparator(summaryOneTarget(dataList, "total_weight").toFixed(3).replace('.', ',')) : dataList.length > 0 ? thousandSeparator(dataList[0].total_weight) : "-" }} kg
                    </span>
                  </th>
                  <th
                    class="table-header uk-table-small uk-width-auto"
                    colspan="2"
                  >
                    <span class="uk-text-bold">
                      <!-- Summary from average_weight -->
                      {{
                        dataList.length > 1
                          ? float3Decimals(summaryOneTarget(dataList, "total_weight") / summaryOneTarget(dataList, "number_of_tail"))
                          : dataList.length > 0
                            ? roundedToFloat3Decimals(dataList[0].average_weight)
                            : "-"
                      }}
                      rata/kg
                    </span>
                  </th>
                  <th class="table-header uk-table-small uk-width-auto">
                    <span class="uk-text-bold">
                      <!-- Summary from total_gross_income -->
                      Rp {{ dataList.length > 1 ? thousandSeparator(summaryOneTarget(dataList, "total_gross_income")) : dataList.length > 0 ? thousandSeparator(dataList[0].total_gross_income) : "-" }}
                    </span>
                  </th>
                </tr>
              </tbody>
              <empty-table
                v-else
                :colspan="10"
              />
            </template>
            <template v-else>
              <loading-table :colspan="10" />
            </template>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from "@/utils/constant"
import { float0Decimals, currency, roundedToFloat3Decimals, dateUtcParanjeString, thousandSeparator, thousandSeparatorFloat, float1Decimals, float3Decimals, summaryOneTarget, summaryTwoTarget } from "@/utils/formater"
import EmptyTable from "@/components/globals/table/EmptyTable"
import LoadingTable from "@/components/globals/table/LoadingTable"

export default {
  components: {
    EmptyTable,
    LoadingTable
  },
  props: {
    listProduction: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      isLoading: false,
      dataList: []
    }
  },
  watch: {
    listProduction() {
      this.isLoading = true
      if (this.listProduction) {
        this.dataList = this.listProduction ? this.listProduction : []
        this.isLoading = false
      }
      this.isLoading = false
    }
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    thousandSeparator(value) {
      return thousandSeparator(value)
    },
    thousandSeparatorFloat(value, commaBehind) {
      return thousandSeparatorFloat(value, commaBehind)
    },
    float1Decimals(value) {
      return float1Decimals(value)
    },
    float3Decimals(value) {
      return float3Decimals(value)
    },
    roundedToFloat3Decimals(value, limit) {
      return roundedToFloat3Decimals(value, limit)
    },
    summaryOneTarget(value, target) {
      return summaryOneTarget(value, target)
    },
    summaryTwoTarget(value, target1, target2) {
      return summaryTwoTarget(value, target1, target2)
    },
    setCurrency(s) {
      const newNum = float0Decimals(s)
      return currency(newNum)
    }
  }
}
</script>

<style scoped>
h1 {
  color: #1f2e28;
  font-size: 14px;
}
.table-header {
  vertical-align: inherit;
}
.uk-text-center {
  border-left: 1px solid #b0dfcc;
  border-right: 1px solid #b0dfcc;
}
.act-std {
  padding: 0;
  height: 24px;
}
</style>
