<template>
  <div class="uk-margin-top">
    <h1 class="text-medium">
      PAKAN
    </h1>
    <div class="uk-margin-top">
      <div class="uk-overflow-auto">
        <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider">
          <thead class="thead-table-paranje">
            <tr>
              <th
                class="table-header uk-table-small uk-width-auto"
                rowspan="2"
              >
                <span class="uk-text-bold">No. </span>
              </th>
              <th
                class="table-header uk-table-expand uk-width-auto"
                rowspan="2"
              >
                <span class="uk-text-bold">Kode Stock Transfer</span>
              </th>
              <th
                class="table-header uk-table-expand uk-width-auto"
                rowspan="2"
              >
                <span class="uk-text-bold">Tanggal</span>
              </th>
              <th
                class="table-header uk-table-expand uk-width-auto"
                rowspan="2"
              >
                <span class="uk-text-bold">Nama Barang</span>
              </th>
              <th
                class="table-header uk-table-expand uk-width-auto"
                rowspan="2"
              >
                <span class="uk-text-bold">Kuantitas</span>
              </th>
              <th
                class="table-header uk-table-expand uk-width-auto"
                rowspan="2"
              >
                <span class="uk-text-bold">Satuan</span>
              </th>
              <th
                class="table-header uk-table-expand uk-width-auto"
                rowspan="2"
              >
                <span class="uk-text-bold">Harga</span>
              </th>
              <th
                class="table-header uk-table-expand uk-width-auto"
                rowspan="2"
              >
                <span class="uk-text-bold">Jumlah</span>
              </th>
            </tr>
          </thead>
          <template v-if="!dataList.isLoading">
            <tbody
              v-if="dataList.data.length > 0"
              style="border: 1px solid #e5e5e5"
            >
              <tr
                v-for="(data, i) in dataList.data"
                :key="i"
                style="cursor: pointer"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ data.code || data.stock_transfer_code || '-' }}</td>
                <td>{{ formatDate(data.transaction_at) || '-' }}</td>
                <td>{{ data.item_name || '-' }}</td>
                <td>{{ thousandSeparator(data.delivery_quantity) || thousandSeparator(data.quantity) || '-' }}</td>
                <td>{{ data.unit_code || data.unit || '-' }}</td>
                <td>{{ setCurrency(data.price ? data.price : data.contract_price_unit) || '-' }}</td>
                <td>{{ setCurrency(data.total_price ? data.total_price : data.price_contract_partner) || '-' }}</td>
              </tr>
              <tr>
                <th
                  class="table-header uk-table-small uk-width-auto uk-text-right"
                  colspan="4"
                >
                  <span class="uk-text-bold">Total Pemakaian Pakan</span>
                </th>
                <th
                  class="table-header uk-table-small uk-width-auto"
                  colspan="3"
                >
                  <span class="uk-text-bold">
                    {{ 
                      detailRhpp.stock_transfer_sum.feed_quantity_total || '-'
                    }} kg
                  </span>
                </th>
                <th
                  class="table-header uk-table-small uk-width-auto"
                >
                  <span class="uk-text-bold">
                    {{
                      setCurrency(detailRhpp.stock_transfer_sum.feed_price_total) || '-' 
                    }}
                  </span>
                </th>
              </tr>
            </tbody>
            <empty-table
              v-else
              :colspan="8"
            />
          </template>
          <template v-else>
            <loading-table :colspan="8" />
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import EmptyTable from "@/components/globals/table/EmptyTable"
import LoadingTable from "@/components/globals/table/LoadingTable"
import { 
  capitalize,
  dateUtcParanjeString, 
  thousandSeparator, summaryOneTarget, 
  unitPrintNoDiff, summaryTwoTarget,
  float0Decimals, currency} 
from "@/utils/formater"
import "vue-multiselect/dist/vue-multiselect.min.css"

export default {
  components: {
    EmptyTable,
    LoadingTable
  },
  props: {
    detailRhpp: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      dataList: {
        data: [],
        isLoading: true
      }
    }
  },
  computed: {
    ...mapGetters({
      listSapronakPakan: "rhpp/listSapronakPakan"
    })
  },
  watch: {
    async listSapronakPakan() {
      if (this.listSapronakPakan.length > 0) {
        return (this.dataList = {
          data: this.listSapronakPakan,
          isLoading: false
        })
      }
      return (this.dataList = {
        data: [],
        isLoading: false
      })
    }
  },
  methods: {
    unitPrintNoDiff(array, target){
      return unitPrintNoDiff(array, target)
    },
    capitalize(string) {
      return capitalize(string)
    },
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    thousandSeparator(value) {
      return thousandSeparator(value)
    },
    summaryOneTarget(value, target) {
      return summaryOneTarget(value, target)
    },
    summaryTwoTarget(value, target1, target2) {
      return summaryTwoTarget(value, target1, target2)
    },
    setCurrency(s) {
      const newNum = float0Decimals(s)
      return currency(newNum)
    }
  }
}
</script>

<style scoped>
h1 {
  color: #1f2e28;
  font-size: 14px;
}

.table-header {
  vertical-align: inherit;
}

.uk-text-center {
  border-left: 1px solid #b0dfcc;
  border-right: 1px solid #b0dfcc;
}

.act-std {
  padding: 0;
  height: 24px;
}
</style>
