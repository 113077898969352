<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              No. RHPP
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.rhpp.number"
                v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                class="uk-input"
                type="number"
                name="number"
                placeholder="Masukkan No. RHPP..."
                :class="{'uk-form-danger': errors.has('number')}"
              >
              <div
                v-show="errors.has('number')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('number') }}
              </div>
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Mitra
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.rhpp.partner_name"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Kode Siklus
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                id="log_cycle_code" 
                v-model="codeChoose" 
                v-validate="'required'"
                label="cycle_code" 
                track-by="cycle_code" 
                name="log_cycle_code" 
                placeholder="Pilih..." 
                open-direction="bottom"
                :class="{'uk-form-danger': errors.has('log_cycle_code')}"
                :options="cycle_code_list" 
                :searchable="true"
                :loading="isLoading" 
                :max-height="500"
                :disabled="pathName.includes('edit')"
                @search-change="onChange"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('log_cycle_code')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('log_cycle_code') }}
              </div>
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Kandang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.rhpp.cage_name"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Alamat
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <textarea
                v-model="formData.rhpp.cage_address"
                :class="{'uk-textarea': true}"
                rows="5"
                disabled
              />
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Siklus ke
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.rhpp.cycle_count"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Surat Jalan DOC
            </div>
            <div
              v-if="!formData.rhpp_file.delivery_order_doc"
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
              uk-form-custom
            >
              <input
                type="file"
                accept="application/pdf"
                multiple
                @change="(event) => handleUploadFile(event, 'delivery_order_doc')"
              >
              <div
                class="uk-inline"
                tabindex="-1"
              >
                <img
                  :src="`${images}/icon/up-arrow.svg`"
                  alt="icon arrow"
                  class="uk-form-icon uk-form-icon-flip icon-rotate"
                >
                <input
                  class="uk-input"
                  placeholder="Unggah Dokumen"
                >
              </div>
            </div>
            <div
              v-else
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-flex uk-flex-between"
            >
              <span class="truncate">
                {{
                  typeof formData.rhpp_file.delivery_order_doc !== 'object' ? formData.rhpp_file.delivery_order_doc.split('/')[3] : formData.rhpp_file.delivery_order_doc.name
                }}
              </span>
              <div class="uk-flex">
                <img
                  :src="`${images}/icon/eye.svg`"
                  alt=""
                  class="uk-margin-small-right"
                  @click="handlePreviewImage('delivery_order_doc')"
                >
                <img
                  :src="`${images}/icon/close-green.svg`"
                  alt=""
                  class="uk-margin-small-right"
                  @click="handleRemoveLetter('delivery_order_doc')"
                >
              </div>
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Surat Jalan OVK
            </div>
            <div
              v-if="!formData.rhpp_file.delivery_order_ovk"
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
              uk-form-custom
            >
              <input
                type="file"
                accept="application/pdf"
                multiple
                @change="(event) => handleUploadFile(event, 'delivery_order_ovk')"
              >
              <div
                class="uk-inline"
                tabindex="-1"
              >
                <img
                  :src="`${images}/icon/up-arrow.svg`"
                  alt="icon arrow"
                  class="uk-form-icon uk-form-icon-flip icon-rotate"
                >
                <input
                  class="uk-input"
                  placeholder="Unggah Dokumen"
                >
              </div>
            </div>
            <div
              v-else
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-flex uk-flex-between"
            >
              <span class="truncate">
                {{
                  typeof formData.rhpp_file.delivery_order_ovk !== 'object' ? formData.rhpp_file.delivery_order_ovk.split('/')[3] : formData.rhpp_file.delivery_order_ovk.name
                }}
              </span>
              <div class="uk-flex">
                <img
                  :src="`${images}/icon/eye.svg`"
                  alt=""
                  class="uk-margin-small-right"
                  @click="handlePreviewImage('delivery_order_ovk')"
                >
                <img
                  :src="`${images}/icon/close-green.svg`"
                  alt=""
                  class="uk-margin-small-right"
                  @click="handleRemoveLetter('delivery_order_ovk')"
                >
              </div>
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Surat Jalan Pakan
            </div>
            <div
              v-if="!formData.rhpp_file.delivery_order_feed"
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
              uk-form-custom
            >
              <input
                type="file"
                accept="application/pdf"
                multiple
                @change="(event) => handleUploadFile(event, 'delivery_order_feed')"
              >
              <div
                class="uk-inline"
                tabindex="-1"
              >
                <img
                  :src="`${images}/icon/up-arrow.svg`"
                  alt="icon arrow"
                  class="uk-form-icon uk-form-icon-flip icon-rotate"
                >
                <input
                  class="uk-input"
                  placeholder="Unggah Dokumen"
                >
              </div>
            </div>
            <div
              v-else
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-flex uk-flex-between"
            >
              <span class="truncate">
                {{
                  typeof formData.rhpp_file.delivery_order_feed !== 'object' ? formData.rhpp_file.delivery_order_feed.split('/')[3] : formData.rhpp_file.delivery_order_feed.name
                }}
              </span>
              <div class="uk-flex">
                <img
                  :src="`${images}/icon/eye.svg`"
                  alt=""
                  class="uk-margin-small-right"
                  @click="handlePreviewImage('delivery_order_feed')"
                >
                <img
                  :src="`${images}/icon/close-green.svg`"
                  alt=""
                  class="uk-margin-small-right"
                  @click="handleRemoveLetter('delivery_order_feed')"
                >
              </div>
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Data Timbang
            </div>
            <div
              v-if="!formData.rhpp_file.weighing_data"
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
              uk-form-custom
            >
              <input
                type="file"
                accept="application/pdf"
                multiple
                @change="(event) => handleUploadFile(event, 'weighing_data')"
              >
              <div
                class="uk-inline"
                tabindex="-1"
              >
                <img
                  :src="`${images}/icon/up-arrow.svg`"
                  alt="icon arrow"
                  class="uk-form-icon uk-form-icon-flip icon-rotate"
                >
                <input
                  class="uk-input"
                  placeholder="Unggah Dokumen"
                >
              </div>
            </div>
            <div
              v-else
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-flex uk-flex-between"
            >
              <span class="truncate">
                {{
                  typeof formData.rhpp_file.weighing_data !== 'object' ? formData.rhpp_file.weighing_data.split('/')[3] : formData.rhpp_file.weighing_data.name
                }}
              </span>
              <div class="uk-flex">
                <img
                  :src="`${images}/icon/eye.svg`"
                  alt=""
                  class="uk-margin-small-right"
                  @click="handlePreviewImage('weighing_data')"
                >
                <img
                  :src="`${images}/icon/close-green.svg`"
                  alt=""
                  class="uk-margin-small-right"
                  @click="handleRemoveLetter('weighing_data')"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Tanggal Chick Out
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.rhpp.chick_out"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Pajak Penghasilan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                id="income_tax" 
                v-model="formData.rhpp.income_tax"
                v-validate="{ required: true, regex: '[^-]|[^/s]' }"
                label="name"
                name="income_tax"
                track-by="name"
                :options="taxList"
                disabled
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errorIncomeTax"
                class="uk-text-small uk-text-danger"
              >
                {{ 'Belum ada data NPWP' }}
              </div>
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              NPWP
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.rhpp.npwp"
                v-validate="{ required: true, regex: '[^-]|[^/s]' }"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
            <div
              v-show="errors.has('income_tax')"
              class="uk-text-small uk-text-danger"
            >
              {{ errors.first('income_tax') }}
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Biaya Operasional
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-inline">
                <span
                  class="uk-form-icon"
                  uk-icon
                >Rp</span>
                <input
                  v-model="formData.rhpp.price_type"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Subsidi
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                id="is_subsidy" 
                v-model="formData.rhpp.is_subsidy" 
                v-validate="'required'"
                label="name" 
                name="is_subsidy" 
                :class="{'uk-form-danger': errors.has('is_subsidy')}"
                track-by="name" 
                placeholder="Pilih..." 
                open-direction="bottom" 
                :options="installmentList" 
                :searchable="true"
                :loading="isLoading" 
                :close-on-select="true" 
                :max-height="500"
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('is_subsidy')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('is_subsidy') }}
              </div>
            </div>
          </div>
          <div
            v-if="subsidyValue"
            class="uk-grid uk-grid-small"
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Jumlah Subsidi
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-input currency-field">
                Rp
                <input
                  v-model="formData.rhpp.subsidy"
                  v-validate="{ required: true, regex: '^[0-9 _]*[0-9][0-9 _]*$' }"
                  style="border: none"
                  class="uk-input"
                  minlength="1"
                  maxlength="9"
                  type="text"
                  name="subsidy"
                  placeholder="0"
                  :class="{'uk-form-danger': errors.has('subsidy')}"
                >
              </div>
              <div
                v-show="errors.has('subsidy')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('subsidy') }}
              </div>
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Cicilan Dana Jaminan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                id="guarantee_installment" 
                v-model="formData.rhpp.guarantee_installment" 
                v-validate="'required'"
                label="name" 
                name="guarantee_installment" 
                :class="{'uk-form-danger': errors.has('guarantee_installment')}"
                track-by="name" 
                placeholder="Pilih..." 
                open-direction="bottom" 
                :options="installmentList" 
                :searchable="true"
                :loading="isLoading" 
                :close-on-select="true" 
                :max-height="500"
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('guarantee_installment')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('guarantee_installment') }}
              </div>
            </div>
          </div>
          <!-- <div
            v-if="showInstallment"
            class="uk-grid uk-grid-small"
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Penangguhan Dana Jaminan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                id="guarantee_fund"
                v-model="formData.rhpp.guarantee_fund"
                v-validate="'required'"
                label="name" 
                name="guarantee_fund"
                :class="{'uk-form-danger': errors.has('guarantee_fund')}"
                track-by="name" 
                placeholder="Pilih..." 
                open-direction="bottom" 
                :options="listDeferralGuarantee" 
                :searchable="true"
                :loading="isLoading" 
                :close-on-select="true" 
                :max-height="500"
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('guarantee_fund')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('guarantee_fund') }}
              </div>
            </div>
          </div> -->
          <div
            v-if="showInstallment"
            class="uk-grid uk-grid-small"
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Jumlah Cicilan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-input currency-field">
                Rp
                <input
                  v-model="formData.rhpp.installment_amount"
                  v-validate="{ required: true, regex: '^[0-9 _]*[0-9][0-9 _]*$' }"
                  style="border: none"
                  class="uk-input"
                  minlength="1"
                  maxlength="9"
                  type="text"
                  name="installment_amount"
                  placeholder="0"
                  :class="{'uk-form-danger': errors.has('installment_amount')}"
                >
              </div>
              <div
                v-show="errors.has('installment_amount')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('installment_amount') }}
              </div>
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Penyelesaian RHPP Sebelumnya
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                id="seperator_previous_rhpp_solution"
                v-model="formData.rhpp.seperator_previous_rhpp_solution"
                v-validate="'required'"
                label="name" 
                name="seperator_previous_rhpp_solution"
                :class="{'uk-form-danger': errors.has('seperator_previous_rhpp_solution')}"
                track-by="name"
                placeholder="Pilih..." 
                open-direction="bottom"
                :options="listPreviousRhppSolution" 
                :searchable="true"
                :loading="isLoading" 
                :close-on-select="true" 
                :max-height="500"
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('seperator_previous_rhpp_solution')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('seperator_previous_rhpp_solution') }}
              </div>
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Jumlah Penyelesaian RHPP sebelumnya
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-input currency-field">
                Rp
                <input
                  v-model="formData.rhpp.previous_rhpp_solution"
                  v-validate="{ required: true, regex: '^[0-9 _]*[0-9][0-9 _]*$' }"
                  style="border: none"
                  minlength="1"
                  maxlength="9"
                  class="uk-input"
                  type="text"
                  name="previous_rhpp_solution"
                  placeholder="0"
                  :class="{'uk-form-danger': errors.has('previous_rhpp_solution')}"
                >
              </div>
              <div
                v-show="errors.has('previous_rhpp_solution')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('previous_rhpp_solution') }}
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-text-right uk-margin-top">
            <!-- :disabled="errorInstallmentAmount!=''"   reminder-->
            <button
              class="uk-button uk-button-primary uk-margin-left"
              type="button"

              @click="calculate()"
            >
              <div
                v-if="isLoading"
                uk-spinner
              />
              <div
                v-else
              >
                Hitung
              </div>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="!calculatePressed && isAdd"
        class="uk-width-1-1 uk-text-right uk-margin-top"
      >
        <button
          class="uk-button uk-button-danger"
          type="button"
          @click="goToBack"
        >
          <img
            v-lazy="`${images}/icon/close.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Batal
        </button>
        <button
          class="uk-button uk-button-disabled uk-margin-left"
          type="button"
          disabled
        >
          <img
            v-lazy="`${images}/icon/save.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          <span
            v-if="isLoading"
            uk-spinner
            class="button-spinner"
          /><span style="color: #FFFFFF;">Simpan</span>
        </button>
      </div>
    </div>
    <modal-preview-image :blob="blob" />
    <modal-warning :field-fault="fieldFault" />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE, TAX_LIST } from '@/utils/constant'
import { stringNotifDanger, notificationSuccess } from '@/utils/notification'
import { STATUS, INSTALMENT_LIST, PREVIOUS_RHPP_SOLUTION, DEFERRAL_GUARANTEE } from '@/utils/constant'
import ModalPreviewImage from '.././ModalPreviewPDF'
import ModalWarning from '../ModalWarning.vue'

export default {
  components: {
    ModalPreviewImage,
    ModalWarning
  },
  props: {
    showCalculation: {
      required: true,
      type: Function
    },
    detailRhpp: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      fieldFault: '',
      errorIncomeTax: false,
      isAdd: false,
      calculatePressed: false,
      images: PREFIX_IMAGE,
      image_url: process.env.VUE_APP_IMAGE_URL,
      isLoading: false,
      status: STATUS,
      dateJoin: new Date(),
      formatDate: 'DD/MM/YYYY',
      formData: {
        rhpp: {},
        rhpp_file: {}
      },
      codeChoose: {},
      cycle_code_list: [],
      installmentList: INSTALMENT_LIST,
      listPreviousRhppSolution: PREVIOUS_RHPP_SOLUTION,
      listDeferralGuarantee: DEFERRAL_GUARANTEE,
      taxList: TAX_LIST,
      subsidyValue: false,
      showInstallment: false,
      showButton: false,
      debounce: null,
      blob: {},
      pathName: this.$route.fullPath,
      docChanges: false,
      ovkChanges: false,
      feedChanges: false,
      weighingChanges: false,
      fileDeleterDoc: '',
      fileDeleterOvk: '',
      fileDeleterFeed: '',
      fileDeleterWeighing: '',
      getInstallmentAmount: {}
    }
  },
  computed: {
    ...mapGetters({
      listLogCycle: 'rhpp/listLogCycle',
      metaLogCycle: 'rhpp/metaLogCycle',
      dataCreate: 'rhpp/dataCreate',
      dataUpdateRhpp: 'rhpp/dataUpdateRhpp',
      dataLogCycle: 'rhpp/dataLogCycle',
      isFileChanges: 'rhpp/fileChanges',
      docFileIsUploaded: 'rhpp/docFileIsUploaded',
      ovkFileIsUploaded: 'rhpp/ovkFileIsUploaded',
      feedFileIsUploaded: 'rhpp/feedFileIsUploaded',
      weighingFileIsUploaded: 'rhpp/weighingFileIsUploaded'
    })
  },
  watch: {
    listLogCycle () {
      if (this.listLogCycle) {
        this.cycle_code_list = this.listLogCycle.data
      }
    },
    docFileIsUploaded(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`)
      if(this.fileDeleterDoc != 'delivery_order_doc'){
        if(this.isFileChanges && newValue == true){
          setTimeout(() =>{
            notificationSuccess('Berhasil Mengunggah Surat Jalan DOC!'),1500
          })
          this.docChanges = 'done'
          this.isFileChanges = false
        } else if (newValue.length >5) {
          this.docChanges = 'done'
          stringNotifDanger('Gagal Mengunggah Surat Jalan DOC!: ' + newValue),1500
        }
        this.checkAllUploaded()
      }
    },
    ovkFileIsUploaded(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`)
      if(this.fileDeleterOvk != 'delivery_order_ovk'){
        if(this.isFileChanges && newValue == true){
          setTimeout(() =>{
            notificationSuccess('Berhasil Mengunggah Surat Jalan OVK!'),1500
          })
          this.ovkChanges = 'done'
          this.isFileChanges = false
        } else if (newValue.length >5) {
          this.ovkChanges = 'done'
          stringNotifDanger('Gagal Mengunggah Surat Jalan OVK!: ' + newValue),1500
        }
        this.checkAllUploaded()
      }
    },
    feedFileIsUploaded(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`)
      if(this.fileDeleterFeed != 'delivery_order_feed'){
        if(this.isFileChanges && newValue == true){
          setTimeout(() =>{
            notificationSuccess('Berhasil Mengunggah Surat Jalan Pakan!'),1500
          })
          this.feedChanges = 'done'
          this.isFileChanges = false
        } else if (newValue.length >5) { 
          this.feedChanges = 'done'
          stringNotifDanger('Gagal Mengunggah Surat Jalan Pakan!: ' + newValue),1500
        }
        this.checkAllUploaded()
      }
    },
    weighingFileIsUploaded(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`)
      if(this.fileDeleterWeighing != 'weighing_data'){
        if(this.isFileChanges && newValue == true){
          setTimeout(() =>{
            notificationSuccess('Berhasil Mengunggah Data Timbang!'),1500
          })
          this.weighingChanges = 'done'
          this.isFileChanges = false
        } else if (newValue.length >5) {
          this.weighingChanges = 'done'
          stringNotifDanger('Gagal Mengunggah Data Timbang!: ' + newValue),1500
        }
        this.checkAllUploaded()
      }
    },
    dataCreate() {
      if (this.dataCreate) {
        if (this.dataCreate.data) {
          if(this.docChanges){
            const formDataDoc = new FormData()
            formDataDoc.append('id', this.dataCreate.data.rhpp.id)
            formDataDoc.append('file', this.formData.rhpp_file.delivery_order_doc)
            this.uploadFileDoc(formDataDoc)
          }
          if(this.ovkChanges){
            const formDataOVk = new FormData()
            formDataOVk.append('id', this.dataCreate.data.rhpp.id)
            formDataOVk.append('file', this.formData.rhpp_file.delivery_order_ovk)
            this.uploadFileOvk(formDataOVk)
          }
          if(this.feedChanges){
            const formDataFeed = new FormData()
            formDataFeed.append('id', this.dataCreate.data.rhpp.id)
            formDataFeed.append('file', this.formData.rhpp_file.delivery_order_feed)
            this.uploadFileFeed(formDataFeed)
          }
          if(this.weighingChanges){
            const formDataWeighing = new FormData()
            formDataWeighing.append('id', this.dataCreate.data.rhpp.id)
            formDataWeighing.append('file', this.formData.rhpp_file.weighing_data)
            this.uploadFileWeighing(formDataWeighing)
          }
        }
      }
    },
    dataUpdateRhpp() {
      if (this.dataUpdateRhpp) {
        if (this.dataUpdateRhpp.data && this.isFileChanges) {
          if(this.docChanges){
            const formDataDoc = new FormData()
            formDataDoc.append('id', this.dataUpdateRhpp.data.rhpp.id)
            formDataDoc.append('file', this.formData.rhpp_file.delivery_order_doc)
            this.uploadFileDoc(formDataDoc)
          }
          if(this.ovkChanges){
            const formDataOVk = new FormData()
            formDataOVk.append('id', this.dataUpdateRhpp.data.rhpp.id)
            formDataOVk.append('file', this.formData.rhpp_file.delivery_order_ovk)
            this.uploadFileOvk(formDataOVk)
          }
          if(this.feedChanges){
            const formDataFeed = new FormData()
            formDataFeed.append('id', this.dataUpdateRhpp.data.rhpp.id)
            formDataFeed.append('file', this.formData.rhpp_file.delivery_order_feed)
            this.uploadFileFeed(formDataFeed)
          }
          if(this.weighingChanges){
            const formDataWeighing = new FormData()
            formDataWeighing.append('id', this.dataUpdateRhpp.data.rhpp.id)
            formDataWeighing.append('file', this.formData.rhpp_file.weighing_data)
            this.uploadFileWeighing(formDataWeighing)
          }
        }
        if(this.fileDeleterDoc != ''){
          this.handleDeleteFile(this.fileDeleterDoc)
        }
        if(this.fileDeleterOvk != '') {
          this.handleDeleteFile(this.fileDeleterOvk)
        }
        if(this.fileDeleterFeed != ''){
          this.handleDeleteFile(this.fileDeleterFeed)
        } 
        if(this.fileDeleterWeighing != ''){
          this.handleDeleteFile(this.fileDeleterWeighing) 
        }
      }
    },
    detailRhpp() {
      if (this.detailRhpp) {
        // ===> This section parses detailed data for form editing needs
        this.getListLogCycle({
          ...this.metaLogCycle,
          is_rhpp: false,
          name: this.detailRhpp.rhpp.log_cycle_code,
          field: 'edit rhpp'
        })
        this.formData = {
          ...this.detailRhpp,
          rhpp: {
            ...this.detailRhpp.rhpp,
            income_tax: this.taxList.find(item => item.value == this.detailRhpp.rhpp.income_tax),
            guarantee_fund: this.listDeferralGuarantee.find(item => item.value == this.detailRhpp.rhpp.guarantee_fund),
            seperator_previous_rhpp_solution: this.listPreviousRhppSolution.find(item => item.value == this.detailRhpp.rhpp.seperator_previous_rhpp_solution),
            guarantee_installment: this.installmentList.find(item => item.value == this.detailRhpp.rhpp.guarantee_installment),
            is_subsidy: this.installmentList.find(item => item.value == this.detailRhpp.rhpp.is_subsidy)
          }
        }
        this.showInstallment = this.detailRhpp.rhpp.guarantee_installment == 1
        this.subsidyValue = this.detailRhpp.rhpp.is_subsidy == true 
      }
    },
    dataLogCycle() {
      this.codeChoose = this.dataLogCycle.data[0]
      this.getInstallmentAmount = this.codeChoose.installment_amount || 0
      // Parsing data to form disabled field
      this.formData = {
        ...this.formData,
        rhpp: {
          ...this.formData.rhpp,
          log_cycle_id: this.codeChoose.id || '',
          log_cycle_code: this.codeChoose || '',
          partner_name: this.codeChoose.partner_name || '-',
          cage_name: this.codeChoose.name || '-',
          cage_address: this.codeChoose.cage_address || '-',
          cycle_count: this.codeChoose.cycle_count || '-',
          chick_out: this.codeChoose.chick_out || '-',
          income_tax: this.taxList.find(item => item.value == this.codeChoose.income_tax) || '-',
          npwp: this.codeChoose.npwp || '-',
          price_type: this.codeChoose.price_type || '-'
        }
      }
    },
    'formData.rhpp.is_subsidy' () {
      if (this.formData.rhpp.is_subsidy == true || this.formData.rhpp.is_subsidy.value == 1) {
        this.subsidyValue = this.formData.rhpp.is_subsidy.value == true || 1
      } else {
        this.subsidyValue = 0
        this.formData.rhpp.subsidy = 0
      }
    },
    'formData.rhpp.guarantee_installment' () {
      if (typeof this.formData.rhpp.guarantee_installment == 'object') {
        this.showInstallment = this.formData.rhpp.guarantee_installment.value == 1
      } else {
        this.showInstallment = false
        this.formData.rhpp.installment_amount = 0
      }
    },
    //need to watch
    'formData.rhpp.installment_amount' () {
      if(this.formData.rhpp.installment_amount > this.getInstallmentAmount) {
        this.errorInstallmentAmount = `Jumlah Cicilan melebihi sisa Cicilan Dana Jaminan (Rp. ${this.getInstallmentAmount})`
      } else {
        this.errorInstallmentAmount = ''
      }
    },
    codeChoose() {
      if (this.codeChoose) {
        this.formData = {
          ...this.formData,
          rhpp: {
            ...this.formData.rhpp,
            log_cycle_id: this.codeChoose.id || '',
            log_cycle_code: this.codeChoose || '',
            partner_name: this.codeChoose.partner_name || '-',
            cage_name: this.codeChoose.name || '-',
            cage_address: this.codeChoose.cage_address || '-',
            cycle_count: this.codeChoose.cycle_count || '-',
            chick_out: this.codeChoose.chick_out || '-',
            income_tax: this.taxList.find(item => item.value == this.codeChoose.income_tax) || '-',
            npwp: this.codeChoose.npwp || '-',
            price_type: this.codeChoose.price_type || '-'
          }
        }
        this.getInstallmentAmount = this.codeChoose.installment_amount || 0
        if(this.formData.rhpp.installment_amount > this.getInstallmentAmount) {
          this.errorInstallmentAmount = `Jumlah Cicilan melebihi sisa Cicilan Dana Jaminan (Rp. ${this.getInstallmentAmount})`
        } else {
          this.errorInstallmentAmount = ''
        }
      }
    }
  },
  async mounted() {
    await this.getListLogCycle(this.pathName.includes('edit') ? { ...this.metaLogCycle, is_rhpp: false } : this.metaLogCycle)
    this.showButton = this.pathName.includes('edit')
    if (this.listLogCycle) {
      this.cycle_code_list = this.listLogCycle.data
    }
    this.isAdd = this.$route.fullPath.includes('add')
  },
  methods: {
    ...mapActions({
      createRhpp: 'rhpp/createRhpp',
      getListLogCycle: 'rhpp/getListLogCycle',
      uploadFileDoc: 'rhpp/uploadRhppFileDoc',
      uploadFileOvk: 'rhpp/uploadRhppFileOvk',
      uploadFileFeed: 'rhpp/uploadRhppFileFeed',
      uploadFileWeighing: 'rhpp/uploadRhppFileWeighing'
    }),
    ...mapMutations({
      setFileChanges: 'rhpp/SET_FILE_CHANGES',
      setModalAdd: 'farm/SET_MODAL_ADD',
      setMetaLogCycle: 'rhpp/SET_META_LOG_CYCLE',
      setFileUpload: 'rhpp/SET_UPLOAD_FILE_RHPP'
    }),
    checkAllUploaded(){
      if(
        this.docChanges == 'done'  || !this.docChanges &&
        this.ovkChanges == 'done' || !this.ovkChanges &&
        this.feedChanges == 'done' || !this.feedChanges &&
        this.weighingChanges == 'done' || !this.weighingChanges
      ){
        setTimeout(() =>{
          window.location.href = '/admin/rhpp'
        }, 3000)
      }
    },
    handleUploadFile(event, target) {
      this.handleRemoveLetter(target) 
      const checkExtension = /(?:\.([^.]+))?$/
      const isPdf = checkExtension.exec(event.target.files[0].name)[1] == 'pdf'
      const isSizeOk = event.target.files[0].size <= 2e6
      const file = event.target.files[0]
      const preSentence = 'File untuk Surat Jalan DOC, OVK, Pakan, dan Data Timbang'
      if (file && isPdf ) {
        if(isSizeOk){
          switch(target) {
          case 'delivery_order_doc':
            this.docChanges = true
            this.fileDeleterDoc = ''
            break
          case 'delivery_order_ovk':
            this.ovkChanges = true
            this.fileDeleterOvk = ''
            break
          case 'delivery_order_feed':
            this.feedChanges = true
            this.fileDeleterFeed = ''
            break
          case 'weighing_data':
            this.weighingChanges= true
            this.fileDeleterWeighing = ''
            break
          default:
            this.docChanges = false
            this.ovkChanges = false
            this.feedChanges = false
            this.weighingChanges = false
          }
          this.setFileChanges(true)
          this.setFileUpload( this.formData = {
            ...this.formData,
            rhpp_file: {
              ...this.formData.rhpp_file,
              [target]: file
            }
          })
        } else {
          console.error(file.size/1000/1000)
          stringNotifDanger(preSentence+' Tidak Boleh Melebihi 2MB!')
        }
      } else {
        stringNotifDanger(preSentence+' Harus Berupa PDF!')
      }
    },
    handleRemoveLetter(target) {
      this.setFileChanges(false)
      switch(target) {
      case 'delivery_order_doc':
        this.fileDeleterDoc = target
        break
      case 'delivery_order_ovk':
        this.fileDeleterOvk = target
        break
      case 'delivery_order_feed':
        this.fileDeleterFeed = target
        break
      case 'weighing_data':
        this.fileDeleterWeighing= target
        break
      default:
        this.docChanges = false
        this.feedChanges = false
        this.ovkChanges = false
        this.weighingChanges = false
      }
      this.formData = {
        ...this.formData,
        rhpp_file: {
          ...this.formData.rhpp_file,
          [target]: ''
        }
      }
      this.setFileUpload( this.formData = {
        ...this.formData,
        rhpp_file: {
          ...this.formData.rhpp_file,
          [target]: ''
        }
      })
    },
    handleDeleteFile(target) {
      switch(target) {
      case 'delivery_order_doc':
        // eslint-disable-next-line no-case-declarations
        const formDataDoc = new FormData()
        formDataDoc.append('id', this.dataUpdateRhpp.data.rhpp.id)
        this.uploadFileDoc(formDataDoc)
        notificationSuccess('Berhasil Menghapus Surat Jalan Doc!')
        break
      case 'delivery_order_ovk':
        // eslint-disable-next-line no-case-declarations
        const formDataOVk = new FormData()
        formDataOVk.append('id', this.dataUpdateRhpp.data.rhpp.id)
        this.uploadFileOvk(formDataOVk)
        notificationSuccess('Berhasil Menghapus  Surat Jalan OVK!')
        break
      case 'delivery_order_feed':
        // eslint-disable-next-line no-case-declarations
        const formDataFeed = new FormData()
        formDataFeed.append('id', this.dataUpdateRhpp.data.rhpp.id)
        this.uploadFileFeed(formDataFeed)
        notificationSuccess('Berhasil Menghapus Surat Jalan Pakan!')
        break
      case 'weighing_data':
        // eslint-disable-next-line no-case-declarations
        const formDataWeighing = new FormData()
        formDataWeighing.append('id', this.dataUpdateRhpp.data.rhpp.id)
        this.uploadFileWeighing(formDataWeighing)
        notificationSuccess('Berhasil Menghapus Data Timbang!')
        break
      default:
        this.docChanges = false
        this.ovkChanges = false
        this.feedChanges = false
        this.weighingChanges = false
      }
    },
    handlePreviewImage(target) {
      const dataPreview = this.formData.rhpp_file
      const targetType = typeof dataPreview[target] == 'object'
      this.blob = {
        name: targetType ? dataPreview[target].name : dataPreview[target].split('/')[3],
        file: targetType ? URL.createObjectURL(dataPreview[target]) : this.image_url + dataPreview[target]
      }
      return window.UIkit.modal('#modal-preview-image').show()
    },
    calculate() {
      this.isLoading = true
      // console.log(this.formData.rhpp.income_tax)
      if(this.formData.rhpp.income_tax!='-'){
        this.errorIncomeTax = false
        this.isLoading = true
        this.$validator.validateAll().then((success) => {
          if (success) {
            if (this.$validator.errors.any()) return
            let sbutton = this.showCalculation(this.formData)
            if(sbutton && success){
              clearTimeout(this.isLoading)
              this.isLoading = setTimeout(() => {
                this.isLoading = false
              }, 600)
              this.calculatePressed = true
            } else {
              this.isLoading = false
              this.calculatePressed = false
            }
          } else {
            this.calculatePressed = false
            this.isLoading = false
          }
        })
      } else {
        this.fieldFault = 'NPWP'
        window.UIkit.modal('#modal-warning').show()
        this.errorIncomeTax = true
        this.isLoading = false
      }
    },
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    // showSaveConfirmModal() {
    //   this.$validator.validateAll().then((success) => {
    //     if (success) {
    //       if (this.$validator.errors.any()) return
    //       this.setModalAdd(this.detailRhpp)
    //       window.UIkit.modal('#modal-save-confirm').show()
    //     }
    //   })
    // },
    async onChange (value) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.getListLogCycle({
          ...this.metaLogCycle,
          is_rhpp: this.pathName.includes('add'),
          name: value
        })
      }, 600)
    }
  }
}
</script>

<style scoped>
  .currency-field {
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .icon-rotate {
    transform: rotate(90deg);
    padding: 8px;
  }

  object, iframe {
    border: 3px solid #cecece;
    background: #e9e9e9;
    width: 100vw;
    height: auto;
    overflow: scroll;
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
  }

  .uk-button-disabled{
    background: #C2D6CE;
    border-radius: 8px;
  }
</style>
