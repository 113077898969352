<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <h1 class="text-medium">
        Rekapitulasi Hasil Produksi
      </h1>
      <loading-card v-if="isLoading" />
      <div
        v-else
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Mortalitas
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.mortality"
                class="uk-input"
                type="text"
                disabled="disabling"
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Bobot Badan Rataan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.average_body_weight"
                class="uk-input"
                type="text"
                disabled="disabling"
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Total Pakan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.total_feed"
                class="uk-input"
                type="text"
                disabled="disabling"
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Pakan per ekor
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.feed_per_head"
                class="uk-input"
                type="text"
                disabled="disabling"
              >
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Obat per ekor
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.medicine_per_head"
                class="uk-input"
                type="text"
                disabled="disabling"
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Usia Panen
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.harvest_age"
                class="uk-input"
                type="text"
                disabled="disabling"
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              FCR
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.fcr"
                class="uk-input"
                type="text"
                disabled="disabling"
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Indeks Prestasi
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.grade_point"
                class="uk-input"
                type="text"
                disabled="disabling"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import LoadingCard from "@/components/globals/loading"
import { thousandSeparator, float1Decimals, float2Decimals, float3Decimals, float0Decimals, roundedDecimals, currency } from "@/utils/formater"

export default {
  components: {
    LoadingCard
  },
  props: {
    disabling: {
      required: true,
      type: Boolean
    },
    detailRhpp: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      isLoading: true,
      formData: {}
    }
  },
  computed: {
    ...mapGetters({
      dataRhppRecapitulation: "rhpp/detail"
    })
  },
  watch: {
    detailRhpp() {
      if (this.detailRhpp) {
        this.isLoading = false
        const dataRecap = this.detailRhpp.rhpp_recapitulation
        this.formData = {
          mortality: dataRecap.mortality ? roundedDecimals(dataRecap.mortality).replace(".", ",") + "%" : dataRecap.mortality + "%",
          average_body_weight: dataRecap.average_body_weight ? float3Decimals(dataRecap.average_body_weight).toString().replace(".", ",") + " kg" : dataRecap.average_body_weight + " kg",
          total_feed: dataRecap.total_feed ? thousandSeparator(dataRecap.total_feed).toString() + " kg" : dataRecap.total_feed + " kg",
          feed_per_head: dataRecap.feed_per_head ? roundedDecimals(dataRecap.feed_per_head).toString().replace(".", ",")  + " kg" : dataRecap.feed_per_head+ " kg",
          medicine_per_head: dataRecap.medicine_per_head ? thousandSeparator(this.setCurrency(float0Decimals(dataRecap.medicine_per_head))) : dataRecap.medicine_per_head,
          harvest_age: dataRecap.harvest_age ? float1Decimals(dataRecap.harvest_age).toString().replace(".", ",") + " hari" : dataRecap.harvest_age + " hari",
          fcr: dataRecap.fcr ? float2Decimals(dataRecap.fcr).toString().replace(".", ",") : dataRecap.fcr,
          grade_point: dataRecap.grade_point ? thousandSeparator(float0Decimals(dataRecap.grade_point)) : dataRecap.grade_point
        }
      }
      this.isLoading = false
    }
  },
  async mounted() {
    if (this.$route.fullPath.includes("detail")) {
      if (this.dataRhppRecapitulation) {
        this.formData = this.dataRhppRecapitulation.data.rhpp_recapitulation
      }
    }
  },
  methods: {
    setCurrency(s) {
      const newNum = thousandSeparator(s)
      return currency(newNum)
    }
  }
}
</script>

<style scoped>
h1 {
  color: #1f2e28;
  font-size: 14px;
}
</style>
