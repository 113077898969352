<template>
  <div
    id="modal-warning"
    uk-modal
    esc-close="true"
    bg-close="true"
    container="#container-modal-add-rhpp"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <div class="uk-modal-title uk-text-danger">
        <img
          v-lazy="`${images}/icon/warning.svg`"
          alt=""
          class="uk-margin-small-right"
        >
        <span
          style="font-size: 20px; top: -25px;"
          class="uk-text-bold"
        >Peringatan</span>
      </div>
      <p>
        {{ fieldFault }} Wajib Diisi
      </p>
      <button
        class="uk-width-1-2 uk-button uk-button-primary uk-modal-close"
      >
        Oke
      </button>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'
export default {
  props : {
    fieldFault: {
      required: true,
      type: String
    }
  },
  data() {
    return{
      images: PREFIX_IMAGE
    }
  },
  mounted() {
    console.log(this.fieldFault)
  }
}
</script>

<style>
</style>
