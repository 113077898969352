<template>
  <div
    id="modal-save-confirm"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-add-rhpp"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <div
        class="uk-modal-title"
        style="color:#025231"
      >
        Konfirmasi Simpan
      </div>
      <p style="font-size:15px">
        Apakah kamu ingin menyimpan data ini?
      </p>
      <div style="padding-top:20px">
        <button
          class="uk-button uk-button-primary uk-margin-right uk-modal-close"
          type="button"
          :disabled="is_loading"
        >
          Batal
        </button>
        <button
          class="uk-button uk-button-default"
          type="button"
          :disabled="is_loading"
          @click="handleSave"
        >
          <span
            v-if="is_loading"
            uk-spinner
            class="button-spinner"
          />
          Ya, simpan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import {
  notificationInfo,
  notificationDanger,
  notificationSuccess
} from '@/utils/notification'

export default {
  data() {
    return {
      is_loading: false,
      images: PREFIX_IMAGE,
      pathName: this.$route.fullPath
    }
  },
  computed : {
    ...mapGetters({
      modalAdd: 'farm/modalAdd',
      modalAddCalculate: 'rhpp/modalAddCalculate',
      dataCreate: 'rhpp/dataCreate',
      dataUpdateRhpp: 'rhpp/dataUpdateRhpp',
      isFileChanges: 'rhpp/fileChanges'
    })
  },
  methods: {
    ...mapActions({
      createRhpp: 'rhpp/createRhpp',
      getEditRhpp: 'rhpp/getEditRhpp'
    }),
    async handleSave () {
      const rhpp_stock_transfer_ovk = this.modalAddCalculate.ukirama_stock_transfer_ovk.map(item => 
        ({
          stock_transfer_code: item.code,
          delivery_type: item.description,
          transaction_at: item.transaction_at,
          item_name: item.item_name,
          quantity: item.delivery_quantity,
          unit: item.unit_code,
          price: item.unit_price_plus_unit_price_x_margin,
          total_price: item.price_contract_partner
        })
      )
      try {
        this.is_loading = true
        if (this.pathName.includes('add')) {
          await this.createRhpp({
            rhpp: {
              ...this.modalAdd.rhpp,
              number: parseInt(this.modalAdd.rhpp.number),
              income_tax: this.modalAdd.rhpp.income_tax.value,
              installment_amount: parseInt(this.modalAdd.rhpp.installment_amount),
              previous_rhpp_solution: parseInt(this.modalAdd.rhpp.previous_rhpp_solution)
            },
            rhpp_recapitulation: this.modalAdd.rhpp_recapitulation,
            rhpp_production_profit_sharing: this.modalAdd.rhpp_production_profit_sharing,
            rhpp_stock_transfer_ovk: rhpp_stock_transfer_ovk
          })
          const res = await this.$store.getters['rhpp/dataCreate']
          if (res.message === 'OK') {
            notificationSuccess('Tambah RHPP Berhasil')
            if(this.isFileChanges){
              notificationInfo('Mengunggah File...')
            } else {
              setTimeout(() =>{
                window.location.href = '/admin/rhpp'
              }, 3000)
            }
          } else {
            notificationDanger(`Something went wrong`)
          }
        } else {
          await this.getEditRhpp({
            rhpp: {
              ...this.modalAdd.rhpp,
              number: parseInt(this.modalAdd.rhpp.number),
              income_tax: this.modalAdd.rhpp.income_tax.value,
              installment_amount: parseInt(this.modalAdd.rhpp.installment_amount),
              previous_rhpp_solution: parseInt(this.modalAdd.rhpp.previous_rhpp_solution)
            },
            rhpp_recapitulation: this.modalAdd.rhpp_recapitulation,
            rhpp_production_profit_sharing: this.modalAdd.rhpp_production_profit_sharing,
            rhpp_stock_transfer_ovk: rhpp_stock_transfer_ovk
          })
          const res = await this.$store.getters['rhpp/dataUpdateRhpp']
          if (res.message === 'OK') {
            notificationSuccess('Update RHPP Berhasil')
            if(this.isFileChanges){
              notificationInfo('Mengunggah File...')
            } else {
              setTimeout(() =>{
                window.location.href = '/admin/rhpp'
              }, 3000)
            }
          } else {
            notificationDanger(`Something went wrong`)
          }
        }
      } catch (error) {
        notificationDanger(error)
        this.is_loading = false
      }
    }
  }
}
</script>
