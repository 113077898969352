<template>
  <div
    id="modal-preview-image"
    uk-modal
    class="uk-modal-full"
    container="#container-modal-add-rhpp"
  >
    <div class="uk-modal-dialog">
      <div class="uk-modal-title uk-padding-small">
        Preview PDF {{ blob.name }}
        <button
          class="uk-modal-close-full"
          type="button"
          uk-close
        />
      </div>
      <iframe :src="blob.file" />
    </div>
  </div>
</template>

<script>

export default {
  props : {
    blob: {
      required: true,
      type: Object
    }
  }
}

</script>

<style scoped>
  
  .uk-modal-dialog {
    min-height: 100vh;
  }

  object, iframe {
    border: 3px solid #cecece;
    background: #e9e9e9;
    width: 100%;
    height: 100vh;
    overflow: scroll;
  }

</style>
