<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        {{ titlePage ? headerTitle + titlePage : headerTitle }}
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <template>
      <container-detail
        v-if="disabling"
        :disabling="disabling"
      />
      <container
        v-else
        :show-calculation="showCalculation"
        :detail-rhpp="detailRhpp"
      />
    </template>

    <template v-if="active">
      <container-laba
        v-if="!disabling"
        :detail-rhpp="detailRhpp"
      />

      <container-rekapitulasi
        :disabling="disabling"
        :detail-rhpp="detailRhpp"
      />

      <container-hasil-produksi
        :list-production="listResultProd"
      />

      <container-sapronak-and-return
        :detail-rhpp="detailRhpp"
        :calculate-result="calculateResult"
        :disabling="disabling"
      />

      <div id="container-modal-add-rhpp" />
    </template>
    <modal-choose-export />
    <modal-out-confirm :target-path="targetPath" />
    <modal-save-confirm />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Container from './Component/Container'
import ContainerDetail from './Component/ContainerInfoDetail'
import ContainerLaba from './Component/ContainerLaba'
import ContainerRekapitulasi from './Component/ContainerRekapitulasi'
import ContainerHasilProduksi from './Component/ContainerHasilProduksi'
import ContainerSapronakAndReturn from './Component/ContainerSapronakAndReturn'
import ModalOutConfirm from './ModalOutConfirm'
import ModalSaveConfirm from './ModalSaveConfirm'
import ModalChooseExport from './ModalChooseExport'
import BreadCrumb from '@/components/globals/breadcrumb'
import getHeaderTitle from '@/utils/header-title'
import { notificationDanger } from '@/utils/notification'

export default {
  components: {
    Container,
    ContainerDetail,
    ContainerLaba,
    ContainerRekapitulasi,
    ContainerHasilProduksi,
    ContainerSapronakAndReturn,
    ModalOutConfirm,
    ModalSaveConfirm,
    ModalChooseExport,
    BreadCrumb
  },
  data() {
    return {
      name: 'RHPP',
      disabling: false,
      active: false,
      status: 'Tambah RHPP',
      detailRhpp: {},
      listResultProd: [],
      titlePage: '',
      targetPath: '',
      breadcrumb: [
        {
          link: '/admin/rhpp',
          title: 'RHPP'
        },
        {
          link: null,
          title: 'Tambah RHPP'
        }
      ]
    }
  },
  beforeRouteLeave (to) {
    this.targetPath = to.fullPath
    if (this.$route.fullPath.includes('detail')) {
      return window.location = this.targetPath
    } return window.UIkit.modal('#modal-out-confirm').show()
  },
  computed: {
    ...mapGetters({
      isCalculated: 'rhpp/isCalculated',
      dataRhpp: 'rhpp/detail',
      listResultProduction: 'rhpp/listResultProduction',
      dataCalculateRhpp: 'rhpp/dataCalculateRhpp',
      metaListResultProduction: 'rhpp/metaListResultProduction'
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  watch: {
    dataRhpp() {
      if (this.dataRhpp) {
        const pathName = this.$route.fullPath
        if (pathName.includes('detail')) {
          this.setMetaResultProduction({
            ...this.metaListResultProduction,
            log_cycle_code: `${this.$route.params.cycle_id}`
          })
        }
      } else {
        if(this.isCalculated){
          this.calculateResult = this.dataCalculateRhpp
        }
      }
    }
  },
  async mounted() {
    const pathName = this.$route.fullPath
    this.handleBreadcrumb(pathName)
    if (pathName.includes('detail') || pathName.includes('edit')) {
      await this.getRhppDetail(this.$route.params.id)
      if (this.dataRhpp) {
        this.detailRhpp = this.dataRhpp.data
        this.titlePage = ` ${this.detailRhpp.rhpp.partner_name} - ${this.detailRhpp.rhpp.log_cycle_code}`
        if (pathName.includes('detail')) return this.handleDataSapronak()
        return this.handleDataEdit(this.dataRhpp.data)
      }
    }
  },
  methods: {
    ...mapActions({
      getRhppDetail: 'rhpp/getRhppDetail',
      getListResultProduction: 'rhpp/getListResultProduction',
      calculateRhpp: 'rhpp/calculateRhpp'
    }),
    ...mapMutations({
      setMetaResultProduction: 'rhpp/SET_META_RESULT_PRODUCTION'
    }),
    async showCalculation(value) {
      const payload = {
        number: parseInt(value.rhpp.number),
        log_cycle_id: value.rhpp.log_cycle_id,
        log_cycle_code: value.rhpp.log_cycle_code.cycle_code,
        guarantee_installment: value.rhpp.guarantee_installment.value,
        installment_amount: parseFloat(value.rhpp.installment_amount) || 0,
        previous_rhpp_solution: parseFloat(value.rhpp.previous_rhpp_solution),
        seperator_previous_rhpp_solution: value.rhpp.seperator_previous_rhpp_solution.value,
        is_subsidy: value.rhpp.is_subsidy.value == 1 ? true : false,
        subsidy: parseFloat(value.rhpp.subsidy) || 0
      }
      try {
        await this.calculateRhpp(payload)
        this.active = true
        const res = await this.$store.getters['rhpp/dataCalculateRhpp']
        if (res) {
          this.setMetaResultProduction({
            ...this.metaListResultProduction,
            log_cycle_code: res.rhpp.log_cycle_code
          })
          this.detailRhpp = {
            ...value,
            rhpp: {
              ...value.rhpp,
              log_cycle_code: value.rhpp.log_cycle_code.cycle_code,
              guarantee_installment: value.rhpp.guarantee_installment.value,
              is_subsidy: value.rhpp.is_subsidy.value == 1 ? true : false,
              seperator_previous_rhpp_solution: value.rhpp.seperator_previous_rhpp_solution.value,
              guarantee_fund: value.rhpp.guarantee_fund ? value.rhpp.guarantee_fund.value : 0
            },
            rhpp_production_profit_sharing: res.rhpp_production_profit_sharing,
            rhpp_recapitulation: res.rhpp_recapitulation
          }
          this.handleDataSapronak()
        }
      } catch(e) {
        notificationDanger(`Something went wrong`)
      }
    },
    async handleDataSapronak() {
      await this.getListResultProduction(this.metaListResultProduction)

      if (this.listResultProduction) {
        this.listResultProd = this.listResultProduction
      }
    },
    handleDataEdit(data) {
      if (data) {
        this.detailRhpp = data
        this.setMetaResultProduction({
          ...this.metaListResultProduction,
          log_cycle_code: data.rhpp.log_cycle_code
        })
        this.handleDataSapronak()
      }
    },
    handleBreadcrumb(target) {
      if (target.includes('detail')) {
        this.disabling = true
        this.active = true
        this.breadcrumb[1].title = 'Detail RHPP'
      } else if (target.includes('edit')) {
        this.breadcrumb[1].title = 'Ubah RHPP'
        this.active = true
        this.disabling = false
      }
    }
  }
}
</script>
