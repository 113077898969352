<template>
  <div
    id="modal-choose-export"
    uk-modal
    esc-close="true"
    bg-close="true"
    container="#container-modal-add-rhpp"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <h4 class="label-green uk-text-left uk-text-bold">
        Export Dokumen RHPP Dengan Format:
      </h4>
      <div class="uk-form-horizontal">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1">
            <label
              class="uk-form-label filter-margin label-green"
              for="log_activity"
            >
              Pilih Format Dokumen
            </label>
            <div class="uk-form-controls">
              <multiselect 
                id="cage-category-stages" 
                v-model="exportPick" 
                label="name"
                name="cage-category-stages" 
                track-by="name" 
                open-direction="bottom" 
                :options="exportOptions" 
                :searchable="true" 
                :close-on-select="true" 
                :max-height="500" 
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div style="padding-top:20px">
        <button
          class="uk-button uk-button-primary uk-float-right"
          type="button"
          @click="handlePrint()"
        >
          <div
            v-if="isLoading"
            uk-spinner
          />
          <div
            v-else
          >
            Simpan
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { PDFDocument } from 'pdf-lib'
import { 
  dateString,
  ifFalsyReturnZero, 
  thousandSeparatorFloat,
  currency,
  float0Decimals,
  float1Decimals,
  float2Decimals,
  float3Decimals,
  capitalize, 
  roundedDecimalsSwapComma, 
  unitPrintNoDiff,
  thousandSeparator,
  summaryTwoTarget,
  summaryOneTarget,
  roundedToFloat3Decimals
} from '@/utils/formater'
import { stringNotifDanger } from '@/utils/notification'
export default {
  data() {
    return{
      image_url: process.env.VUE_APP_IMAGE_URL,
      isLoading: false,
      exportOptions: [
        {name: 'PDF'},
        {name: 'PDF + Lampiran'},
        {name: 'CSV'}
      ],
      exportPick: '',
      dataExport: {},
      titleExcel: [
        {
          value: 'Formulir Isian Rekapitulasi Hasil Produksi Peternak (RHPP)'
        }, {
          value: '1. Informasi Peternak, RHPP , dan Siklus Pemeliharaan'
        }, {
          value: '2. Hasil Produksi'
        }, {
          value: '3. Pemakaian DOC'
        }, {
          value: '4. Pemakaian Pakan'
        }, {
          value: '5. Pemakaian Obat-obatan Vaksin dan Kimia (OVD)'
        }, {
          value: '6. Retur Barang'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isFromSapronak: 'rhpp/isFromSapronak',
      downloadPdfRhpp: 'rhpp/downloadPdfRhpp',
      dataRhpp: 'rhpp/detail',
      dataCalculateRhpp: 'rhpp/detail',
      list_sales_shipment: 'rhpp/listResultProduction',
      list_stock_transfer_feed: 'rhpp/listSapronakPakan',
      list_stock_transfer_doc: 'rhpp/listSapronakDoc',
      list_stock_transfer_ovk: 'rhpp/listSapronakOvk',
      list_stock_transfer_return: 'rhpp/listReturn'
    })
  },
  watch: {
    dataCalculateRhpp() {
      if (this.dataCalculateRhpp) {
        this.dataExport = this.dataCalculateRhpp.data
      }
    }
  },
  mounted(){
    this.exportPick = this.exportOptions.find(exportOptions => exportOptions.name === 'PDF')
  },
  methods: {
    ...mapActions({
      getPrintPdfRhpp: 'rhpp/getPrintPdfRhpp'
    }),
    unitPrintNoDiff(array, target){
      return unitPrintNoDiff(array, target)
    },
    setCurrency(date) {
      const newNum = float0Decimals(date)
      return currency(newNum)
    },
    thousandSeparator(value) {
      return thousandSeparator(value)
    },
    thousandSeparatorFloat(value, decimal) {
      return thousandSeparatorFloat(value, decimal)
    },
    summaryTwoTarget(value, target1, target2) {
      return summaryTwoTarget(value, target1, target2)
    },
    roundedDecimalsSwapComma(value){
      return roundedDecimalsSwapComma(value)
    },
    ifFalsyReturnZero(variable){
      return ifFalsyReturnZero(variable)
    },
    roundedToFloat3Decimals(value, limit) {
      return roundedToFloat3Decimals(value, limit)
    },
    float3Decimals(value) {
      return float3Decimals(value)
    },
    capitalize(string) {
      return capitalize(string)
    },
    float1Decimals(value) {
      return float1Decimals(value)
    },
    float2Decimals(value) {
      return float2Decimals(value)
    },
    summaryOneTarget(value, target) {
      return summaryOneTarget(value, target)
    },
    async handlePrint(){
      if(this.exportPick.name == 'PDF'){
        if (this.dataRhpp.data) {
          this.isLoading = true
          await this.getPrintPdfRhpp(`${this.dataRhpp.data.rhpp.id}?date=${dateString(new Date())}`)
          if (this.downloadPdfRhpp) {
            const file = new Blob([this.downloadPdfRhpp], {type: 'application/pdf'})
            const fileURL = URL.createObjectURL(file)
            const link = document.createElement('a')
            link.href = fileURL
            link.setAttribute('download', 'rhpp-download')
            link.click()
          }
          this.isLoading = false
        }
        window.UIkit.modal('#modal-choose-export').hide()
      } else if (this.exportPick.name == 'CSV') {
        let dataPartner = [], dataLaba = [], dataBagiHasil = [], dataRekap = [], dataShipment = [], dataDoc = [], dataFeed = [], dataOvk = [], dataRetur = []
        let csvContent = "data:text/csv;charset=utf-8,"

        // Part of title for Formulir Isian Rekapitulasi Hasil Produksi Peternak (RHPP)
        csvContent += [
          Object.values(this.titleExcel[0]),
          Object.keys([]).join("\n")
        ].join("\n")

        ///////////////////////////////////// Section for Informasi Peternak dan Siklus Pemeliharaan
        if (this.dataExport !== null) {
          // Part of title table in Informasi Peternak dan Siklus Pemeliharaan
          csvContent += [
            Object.keys([]).join("\n"),
            Object.values(this.titleExcel[1]),
            Object.keys([]).join("\n")
          ].join("\n")

          // Part of data table in Informasi Peternak dan Siklus Pemeliharaan
          dataPartner = [
            {
              title: 'Data Partner'
            },
            {
              title: 'Kode Proyek :',
              value: this.dataExport.rhpp.log_cycle_code || ''
            }, {
              title: 'Nama Mitra :',
              value: this.dataExport.rhpp.partner_name || ''
            }, {
              title: 'Alamat Kandang :',
              value: this.dataExport.rhpp.cage_address.replace(/[\n]/g,', ') || ''
            }, {
              title: 'Siklus Pemeliharaan :',
              value: this.dataExport.rhpp.cycle_count || ''
            }, {
              title: 'NPWP :',
              value: this.dataExport.rhpp.npwp_exist ? 'Ada' : 'Tidak Ada'
            }, {
              title: 'Nomor NPWP (jika ada)',
              value: this.dataExport.rhpp.npwp || ''
            }, {
              title: 'Cicilan Jaminan :',
              value: this.dataExport.rhpp.guarantee_installment ? 'Ada' : 'Tidak Ada'
            }, {
              title: 'Subsidi :',
              value: this.dataExport.rhpp.is_subsidy ? 'Ada' : 'Tidak Ada'
            },
            {
              title: 'Penyesuaian RHPP dari Siklus Sebelumnya :',
              value: this.setCurrency(this.dataExport.rhpp.previous_rhpp_solution) || ''
            }
          ]

          dataLaba = [
            {
              title: 'Perhitungan Laba Hasil Produksi'
            },
            {
              title: 'Total Hasil Produksi: ',
              value: this.setCurrency(ifFalsyReturnZero(this.dataExport.rhpp_production_profit_sharing.total_production)) || ''
            },
            {
              title: 'Total Biaya Sapronak: ',
              value: '- ' + this.setCurrency(ifFalsyReturnZero(this.dataExport.rhpp_production_profit_sharing.total_cost_of_sapronak))
            },
            {
              title: 'Total Retur Barang: ',
              value: this.setCurrency(ifFalsyReturnZero(this.dataExport.rhpp_production_profit_sharing.total_return_of_goods))
            },
            {
              title: 'Laba Hasil Produksi: ',
              value: this.setCurrency(ifFalsyReturnZero(this.dataExport.rhpp_production_profit_sharing.total_production_profit))
            }

          ]

          dataBagiHasil = [
            {
              title: 'Perhitungan Bagi Hasil'
            },
            {
              title: 'Pendapatan Kotor ('+String(this.dataExport.rhpp_production_profit_sharing.sharing_percentage ? this.dataExport.rhpp_production_profit_sharing.sharing_percentage : 60)+')%:',
              value: this.setCurrency(ifFalsyReturnZero(this.dataExport.rhpp_production_profit_sharing.gross_income))  
            },
            {
              title: 'Subsidi:',
              value: this.setCurrency(ifFalsyReturnZero(this.dataExport.rhpp_production_profit_sharing.subsidy))
            },
            {
              title: 'Pajak Penghasilan PPH ' + this.dataExport.rhpp_production_profit_sharing.income_tax_farm_label + '( '+ this.dataExport.rhpp_production_profit_sharing.income_tax_farm +'% ):',
              value: '- ' + this.setCurrency(ifFalsyReturnZero(this.dataExport.rhpp_production_profit_sharing.income_tax))  
            },
            {
              title: 'Cicilan Dana Jaminan:',
              value: this.setCurrency(ifFalsyReturnZero(this.dataExport.rhpp_production_profit_sharing.installment_amount))
            },
            {
              title: 'Penyelesaian RHPP Siklus Sebelumnya:',
              value: this.dataExport.rhpp_production_profit_sharing.seperator_previous_rhpp_solution == 1 ? '+ ' : '- ' + this.setCurrency(ifFalsyReturnZero(this.dataExport.rhpp_production_profit_sharing.previous_rhpp_solution)) 
            },
            {
              title: 'Pendapatan Bersih Mitra:',
              value: this.setCurrency(ifFalsyReturnZero(this.dataExport.rhpp_production_profit_sharing.partner_net_income))  
            },
            {
              title: 'Pendapatan Kotor per Ekor:',
              value: this.setCurrency(ifFalsyReturnZero(this.dataExport.rhpp_production_profit_sharing.gross_income_per_head)) 
            }
          ]

          dataRekap = [
            {
              title: 'Rekapitulasi Hasil Produksi:'
            },
            {
              title: 'Mortalitas:',
              value: this.roundedDecimalsSwapComma(ifFalsyReturnZero(this.dataExport.rhpp_recapitulation.mortality)) + "%"
            },
            {
              title: 'Bobot Badan Rataan:',
              value: float3Decimals(ifFalsyReturnZero(this.dataExport.rhpp_recapitulation.average_body_weight)).toString().replace(".", ",") + " kg"
            },
            {
              title: 'Total Pakan:',
              value: thousandSeparator(ifFalsyReturnZero(this.dataExport.rhpp_recapitulation.total_feed).toString()) + " kg" 
            },
            {
              title: 'Pakan per Ekor:',
              value: roundedDecimalsSwapComma(ifFalsyReturnZero(this.dataExport.rhpp_recapitulation.feed_per_head)).toString() + " kg"
            },
            {
              title: 'Obat per Ekor:',
              value: roundedDecimalsSwapComma(ifFalsyReturnZero(this.dataExport.rhpp_recapitulation.medicine_per_head))
            },
            {
              title: 'Usia Panen:',
              value: float1Decimals(ifFalsyReturnZero(this.dataExport.rhpp_recapitulation.harvest_age)).toString().replace(".", ",") + " hari"
            },
            {
              title: 'FCR:',
              value: float2Decimals(ifFalsyReturnZero(this.dataExport.rhpp_recapitulation.fcr)).toString().replace(".", ",")
            },
            {
              title: 'Indeks Prestasi:',
              value: this.dataExport.rhpp_recapitulation.grade_point ? thousandSeparator(float0Decimals(ifFalsyReturnZero(this.dataExport.rhpp_recapitulation.grade_point))) : this.dataExport.rhpp_recapitulation.grade_point
            }
          ]

          csvContent += [
            Object.keys([]).join("\n"),
            ...dataPartner.map(item => Object.values(item).join(";")),
            Object.keys([]).join("\n"),
            ...dataLaba.map(item => Object.values(item).join(";")),
            Object.keys([]).join("\n"),
            ...dataBagiHasil.map(item => Object.values(item).join(";")),
            Object.keys([]).join("\n"),
            ...dataRekap.map(item => Object.values(item).join(";")),
            Object.keys([]).join("\n")
          ]
            .join("\n")
            .replace(/(^\[)|(\]$)/gm, "")
        }


        ///////////////////////////////////// Section for Hasil Produksi
        let payloadProductionProfit = {}
        let hasilProd
        let dataShipmentTotal = []
        // Part of title table in Hasil Produksi
        csvContent += [
          Object.keys([]).join("\n"),
          Object.values(this.titleExcel[2]),
          Object.keys([]).join("\n")
        ].join("\n")
        // Part of data table in Hasil Produksi
        this.list_sales_shipment ? hasilProd = this.list_sales_shipment : hasilProd = [{'':''}] 
        hasilProd.map((item, index) => {
          payloadProductionProfit = {
            'No': item.code ? index + 1 : ' ',
            'Sales Shipment': item.code || '-',
            'Tanggal': dateString(item.harvest_date) || '-',
            'Usia Panen': item.harvest_age || '-',
            'Nama Pembeli': item.customer_name || '-',
            'Jumlah Ekor': thousandSeparator(item.number_of_tail) || '-',
            'Jumlah Kg': thousandSeparatorFloat(item.total_weight,1) || '-',
            'Rata/Kg': roundedToFloat3Decimals(item.average_weight, 3) || '-',
            'Harga': item.price_tail_contract ? this.setCurrency(item.price_tail_contract) : '-',
            'Total Pendapatan Kotor': item.total_gross_income ? this.setCurrency(item.total_gross_income) : '-'
          }
          dataShipment.push(payloadProductionProfit)
          if (this.list_sales_shipment){
            dataShipmentTotal = [
              {
                value: [
                  ' ',
                  ' ',
                  ' ',
                  ' ',
                
                  'Total Hasil Produksi',
                
                  hasilProd.length > 1 ? thousandSeparator(summaryOneTarget(hasilProd, "number_of_tail")) + ' ekor' : hasilProd.length > 0 ? thousandSeparator(hasilProd[0].number_of_tail) + ' ekor' : "-" ,
                
                  hasilProd.length > 1 ? thousandSeparator(summaryOneTarget(hasilProd, "total_weight").toFixed(3).replace('.', ',')) + ' kg' : hasilProd.length > 0 ? thousandSeparator(hasilProd[0].total_weight) + ' kg' : "-" ,

                  hasilProd.length > 1
                    ? float3Decimals(summaryOneTarget(this.list_sales_shipment, "total_weight") / summaryOneTarget(this.list_sales_shipment, "number_of_tail")).toString().replace('.', ',') 
                  + ' rata/kg' : hasilProd.length > 0
                      ? roundedToFloat3Decimals(hasilProd[0].average_weight)
                    + ' rata/kg' : "-",
                
                  ' ',

                  hasilProd.length > 1 ? 'Rp ' + thousandSeparator(summaryOneTarget(hasilProd, "total_gross_income")) : hasilProd.length > 0 ? 'Rp ' + thousandSeparator(hasilProd[0].total_gross_income) : "-"
            
                ].join(";")
              }
            ]}
          
        })
        csvContent += [
          Object.keys(dataShipment[0]).join(";"),
          ...dataShipment.map(item => Object.values(item).join(";")),
          ...dataShipmentTotal.map(item => Object.values(item).join(";")),
          Object.keys([]).join("\n"),
          Object.keys([]).join("\n")
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "")

        ///////////////////////////////////// Section for Pemakaian DOC
        // Table Head Pemakaian DOC
        let payloadDoc = {}
        let dataDocItemsTotal = []
        csvContent += [
          Object.keys([]).join("\n"),
          Object.values(this.titleExcel[3]),
          Object.keys([]).join("\n")
        ].join("\n")
        // Table Data Pemakaian DOC
        let dataDocItems
        this.list_stock_transfer_doc ?                                 
          dataDocItems = this.list_stock_transfer_doc : dataDocItems = [{}] 
        dataDocItems.map((item, index) => {
          payloadDoc = {
            'No': item.stock_transfer_code ? index + 1 : ' ',
            'Kode Stock Transfer': item.stock_transfer_code || '-',
            'Tanggal': dateString(item.transaction_at) || '-',
            'Nama Barang': item.item_name || '-',
            'Kuantitas': thousandSeparator(item.quantity) || '-',
            'Penyesuaian': item.adjustment || '-',
            'Satuan': capitalize(item.unit) || '-',
            'Harga': this.setCurrency(item.price) || '-',
            'Jumlah': this.setCurrency(item.total_price) || '-'
          }
          dataDoc.push(payloadDoc)
          if (this.list_stock_transfer_doc){
            dataDocItemsTotal = [
              {
                value: [
                  ' ',
                  ' ',
                  ' ',
                  'Total Pemakaian DOC',
                  this.dataExport.stock_transfer_sum.doc_quantity_total + ' ekor' || '-',
                  ' ',
                  ' ',
                  ' ',
                  this.setCurrency(this.dataExport.stock_transfer_sum.doc_price_total) || '-' 
                ].join(";")
              }
            ]}
          
        })
        csvContent += [
          Object.keys(dataDoc[0] ? dataDoc[0] : '').join(";"),
          ...dataDoc.map(item => Object.values(item).join(";")),
          ...dataDocItemsTotal.map(item => Object.values(item).join(";")),
          Object.keys([]).join("\n"),
          Object.keys([]).join("\n")
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "")
      

        ///////////////////////////////////// Section for Pemakaian Pakan
        // Table Head Pemakaian Pakan
        let payloadFeed = {}
        let dataFeedItemsTotal = []
        csvContent += [
          Object.keys([]).join("\n"),
          Object.values(this.titleExcel[4]),
          Object.keys([]).join("\n")
        ].join("\n")
        // Table Data Pemakaian Pakan
        let dataFeedItems
        this.list_stock_transfer_feed ? dataFeedItems = this.list_stock_transfer_feed : dataFeedItems = [{'':''}] 
        dataFeedItems.map((item, index) => {
          payloadFeed = {
            'No': item.stock_transfer_code ? index + 1 : ' ',
            'Kode Stock Transfer': item.stock_transfer_code || '-',
            'Tanggal': dateString(item.transaction_at) || '-',
            'Nama Barang': item.item_name || '-',
            'Kuantitas': thousandSeparator(item.quantity) || '-',
            'Satuan': capitalize(item.unit) || '-',
            'Harga': this.setCurrency(item.price) || '-',
            'Jumlah': this.setCurrency(item.total_price) || '-'
          }
          dataFeed.push(payloadFeed)
          if (this.list_stock_transfer_feed){
            dataFeedItemsTotal = [
              {
                value: [
                  ' ',
                  ' ',
                  ' ',
                  'Total Pemakaian Pakan',
                  this.dataExport.stock_transfer_sum.feed_quantity_total + ' kg' || '-',
                  ' ',
                  ' ',
                  this.setCurrency(this.dataExport.stock_transfer_sum.feed_price_total) || '-'
                ].join(";")
              }
            ]}
        })
        csvContent += [
          Object.keys(dataFeed[0] ? dataFeed[0] : '').join(";"),
          ...dataFeed.map(item => Object.values(item).join(";")),
          ...dataFeedItemsTotal.map(item => Object.values(item).join(";")),
          Object.keys([]).join("\n")
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "")

        ///////////////////////////////////// Section for Pemakaian OVD
        // Table Head Pemakaian OVK
        let payloadOvk = {}
        let dataOvkItemsTotal = []
        csvContent += [
          Object.keys([]).join("\n"),
          Object.values(this.titleExcel[5]),
          Object.keys([]).join("\n")
        ].join("\n")
        // Table Data Pemakaian OVK
        let dataOvkItems
        this.list_stock_transfer_ovk ? dataOvkItems = this.list_stock_transfer_ovk : dataOvkItems = [{}]
        dataOvkItems.map((item, index) => {
          payloadOvk = {
            'No': item.stock_transfer_code ? index + 1 : ' ',
            'Kode Stock Transfer': item.stock_transfer_code || '-',
            'Tanggal': dateString(item.transaction_at) || '-',
            'Nama Barang': item.item_name || '-',
            'Kuantitas': thousandSeparator(item.quantity) || '-',
            'Satuan': capitalize(item.unit) || '-',
            'Tipe Pengiriman': item.delivery_type || item.description || '-',
            'Harga': this.setCurrency(item.price) || '-',
            'Jumlah': this.setCurrency(item.total_price) || '-'
          }
          dataOvk.push(payloadOvk)
          if (this.list_stock_transfer_ovk){
            dataOvkItemsTotal = [
              {
                value: [
                  ' ',
                  ' ',
                  ' ',
                  'Total Pemakaian OVD',
                  this.dataExport.stock_transfer_sum.ovk_quantity_total + ' ' + unitPrintNoDiff(dataOvkItems, 'unit') || '-',
                  ' ',
                  ' ',
                  ' ',
                  this.setCurrency(this.dataExport.stock_transfer_sum.ovk_price_total) || '-'
                ].join(";")
              }
            ]}
        })
        csvContent += [
          Object.keys(dataOvk[0] ? dataOvk[0] : '').join(";"),
          ...dataOvk.map(item => Object.values(item).join(";")),
          ...dataOvkItemsTotal.map(item => Object.values(item).join(";")),
          Object.keys([]).join("\n"),
          Object.keys([]).join("\n"),
          Object.keys([]).join("\n")
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "")

        ///////////////////////////////////// Section for Retur Barang
        // Table Head Retur
        csvContent += [
          Object.keys([]).join("\n"),
          Object.values(this.titleExcel[6]),
          Object.keys([]).join("\n")
        ].join("\n")

        /////// Table Data Retur

        let dataReturItemsTotal = []
        let payloadRetur = {}
        let dataReturItems
        this.list_stock_transfer_return ? dataReturItems = this.list_stock_transfer_return : dataReturItems = [{}]
        dataReturItems.map((item, index) => {
          payloadRetur = {
            'No': item.stock_transfer_code ? index + 1 : ' ',
            'Kode Stock Transfer': item.stock_transfer_code || '-',
            'Tanggal': dateString(item.transaction_at) || '-',
            'Nama Barang': item.item_name || '-',
            'Kuantitas': thousandSeparator(item.quantity) || '-',
            'Satuan': capitalize(item.unit) || '-',
            'Harga': this.setCurrency(item.price) || '-',
            'Jumlah': this.setCurrency(item.total_price) || '-'
          }
          dataRetur.push(payloadRetur)
          if (this.list_stock_transfer_return){
            dataReturItemsTotal = [
              {
                value: [
                  ' ',
                  ' ',
                  ' ',
                  'Total Retur',
                  this.dataExport.stock_transfer_sum.return_quantity_total + ' ' + unitPrintNoDiff(dataReturItems, 'unit') || '-',
                  ' ',
                  ' ',
                  this.setCurrency(this.dataExport.stock_transfer_sum.return_price_total) || '-'
                ].join(";")
              }
            ]}
        })
        csvContent += [
          Object.keys(dataRetur[0] ? dataRetur[0] : '').join(";"),
          ...dataRetur.map(item => Object.values(item).join(";")),
          ...dataReturItemsTotal.map(item => Object.values(item).join(";")),
          Object.keys([]).join("\n"),
          Object.keys([]).join("\n"),
          Object.keys([]).join("\n")
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "")
        
        ///////////////////////////////////// Section download excel
        const data = encodeURI(csvContent)
        const link = document.createElement("a")
        link.setAttribute("href", data)
        link.setAttribute("download", `Formulir Isian Rekapitulasi Hasil Produksi Peternak (RHPP).csv`)
        link.click()
        window.UIkit.modal('#modal-choose-export').hide()
      } else{
        this.pdfLampiran()
      }
    },
    async pdfLampiran(){
      this.isLoading = true
      const sourcePdf = this.dataRhpp.data.rhpp_file
      const suratJalanDoc = this.image_url+`${sourcePdf.delivery_order_doc}`
      const suratJalanPakan =this.image_url+`${sourcePdf.delivery_order_feed}`
      const suratJalanOvk = this.image_url+`${sourcePdf.delivery_order_ovk}`
      const dataTimbang =this.image_url+`${sourcePdf.weighing_data}`

      //take rhpp pdf
      await this.getPrintPdfRhpp(`${this.dataRhpp.data.rhpp.id}?date=${dateString(new Date())}`)
      
      //create blangpdf
      const mergedPdf = await PDFDocument.create()

      // get URl PDF Fetch to arraybuffer
      let pdfRhppFile = this.downloadPdfRhpp
      let urlSjDoc = 
      await fetch(suratJalanDoc)
        .then(res => res.arrayBuffer())
        .catch((error) => {
          stringNotifDanger(error)
          this.isLoading = false
        })
      let urlSjOvk = await fetch(suratJalanOvk)
        .then(res => res.arrayBuffer())
        .catch((error) => {
          stringNotifDanger(error)
          this.isLoading = false
        })
      let urlSjPakan = await fetch(suratJalanPakan)
        .then(res => res.arrayBuffer())
        .catch((error) => {
          stringNotifDanger(error)
          this.isLoading = false
        })
      let urlDataTimbang = await fetch(dataTimbang)
        .then(res => res.arrayBuffer())
        .catch((error) => {
          stringNotifDanger(error)
          this.isLoading = false
        })

      // load pdf after arrayBuffered
      // then copy to mergedPdf if not empty
      
      ///Rhpp
      const pdfRhpp = await PDFDocument.load(pdfRhppFile)
      const copiedPagesRhpp = await mergedPdf.copyPages(pdfRhpp, pdfRhpp.getPageIndices())
      copiedPagesRhpp.forEach((page) => mergedPdf.addPage(page))

      ///delivery order doc
      if(sourcePdf.delivery_order_doc){
        const loadedDoc = await PDFDocument.load(urlSjDoc)
        const copiedPagesDoc = await mergedPdf.copyPages(loadedDoc, loadedDoc.getPageIndices())
        copiedPagesDoc.forEach((page) => mergedPdf.addPage(page))
      }

      ///delivery order ovk
      if(sourcePdf.delivery_order_ovk){
        const loadedOvk = await PDFDocument.load(urlSjOvk)
        const copiedPagesOvk = await mergedPdf.copyPages(loadedOvk, loadedOvk.getPageIndices())
        copiedPagesOvk.forEach((page) => mergedPdf.addPage(page))
      }

      ///delivery order feed
      if(sourcePdf.delivery_order_feed){
        const loadedPakan = await PDFDocument.load(urlSjPakan)
        const copiedPagesFeed = await mergedPdf.copyPages(loadedPakan, loadedPakan.getPageIndices())
        copiedPagesFeed.forEach((page) => mergedPdf.addPage(page))

      }

      ///weighing data
      if(sourcePdf.weighing_data){
        const loadedDt = await PDFDocument.load(urlDataTimbang)
        const copiedPagesDt = await mergedPdf.copyPages(loadedDt, loadedDt.getPageIndices())
        copiedPagesDt.forEach((page) => mergedPdf.addPage(page))
      }
      
      //download
      const mergedPdfFile = await mergedPdf.save()
      const file = new Blob([await mergedPdfFile], {type: 'application/pdf'})
      const fileURL = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = fileURL
      link.setAttribute('download', 'rhpp-dan-lampiran-download')
      link.click()
      this.isLoading = false
    }
    
  }
}
</script>

<style>

</style>
