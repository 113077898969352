<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <loading-card v-if="isLoading" />
      <div
        v-else
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <!-- Section Card Info Kandang -->

        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <h1 class="text-medium">
            Info Kandang
          </h1>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              No. RHPP
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top">
              <input
                v-model="formData.rhpp.number"
                v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                class="uk-input"
                type="text"
                name="number"
                placeholder="Masukkan No. RHPP..."
                :class="{'uk-form-danger': errors.has('number')}"
                disabled="disabling"
              >
              <div
                v-show="errors.has('number')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('number') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Mitra
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top">
              <input
                v-model="formData.rhpp.partner_name"
                class="uk-input"
                type="text"
                disabled="disabling"
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Kode Siklus
            </div>
            <div
              v-if="disabling"
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top"
            >
              <input
                v-model="formData.rhpp.log_cycle_code"
                class="uk-input"
                type="text"
                disabled="disabling"
              >
            </div>
            <div
              v-else
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top"
            >
              <multiselect 
                id="cycle_code" 
                v-model="codeChoose" 
                v-validate="'required'"
                label="cycle_code" 
                name="cycle_code" 
                track-by="cycle_code" 
                placeholder="Pilih..." 
                open-direction="bottom" 
                :options="cycle_code_list" 
                :searchable="true"
                :close-on-select="true" 
                :max-height="500"
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('cycle_code')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('cycle_code') }}
              </div>
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Kandang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top">
              <input
                v-model="formData.rhpp.cage_name"
                class="uk-input"
                type="text"
                disabled="disabling"
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Alamat
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top">
              <textarea
                v-model="formData.rhpp.cage_address"
                :class="{'uk-textarea': true}"
                rows="5"
                disabled="disabling"
              />
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Siklus ke
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top">
              <input
                v-model="formData.rhpp.cycle_count"
                class="uk-input"
                type="text"
                disabled="disabling"
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Tanggal Chick Out
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top">
              <input
                v-model="formData.rhpp.chick_out"
                class="uk-input"
                type="text"
                disabled="disabling"
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-small">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Penangguhan dana jaminan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top">
              <input
                v-model="formData.rhpp.guarantee_fund"
                class="uk-input"
                type="text"
                disabled="disabling"
              >
            </div>
          </div>
          <div class="uk-grid uk-grid-medium">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Surat Jalan DOC
            </div>
            <template v-if="formData.rhpp_file.delivery_order_doc">
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top uk-flex uk-flex-between">
                <span class="truncate">{{ formData.rhpp_file.delivery_order_doc.split('/')[3] }}</span>
                <div class="uk-flex">
                  <img
                    v-lazy="`${images}/icon/eye.svg`"
                    alt=""
                    class="uk-margin-small-right img-click"
                    @click="handlePreviewImage('doc', `${image_url}${formData.rhpp_file.delivery_order_doc}`)"
                  >
                  <img
                    v-lazy="`${images}/icon/export.svg`"
                    alt=""
                    class="uk-margin-small-right img-click"
                    @click="handleDownloadPdf('doc', `${image_url}${formData.rhpp_file.delivery_order_doc}`)"
                  >
                </div>
              </div>
            </template>
            <div
              v-else
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top uk-flex uk-flex-between"
            >
              <span class="label-grey">Tidak ada data</span>
            </div>
          </div>
          <div class="uk-grid uk-grid-medium">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Surat Jalan OVK
            </div>
            <template v-if="formData.rhpp_file.delivery_order_ovk">
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top uk-flex uk-flex-between">
                <span class="truncate">{{ formData.rhpp_file.delivery_order_ovk.split('/')[3] }}</span>
                <div class="uk-flex">
                  <img
                    v-lazy="`${images}/icon/eye.svg`"
                    alt=""
                    class="uk-margin-small-right img-click"
                    @click="handlePreviewImage('ovk', `${image_url}${formData.rhpp_file.delivery_order_ovk}`)"
                  >
                  <img
                    v-lazy="`${images}/icon/export.svg`"
                    alt=""
                    class="uk-margin-small-right img-click"
                    @click="handleDownloadPdf('ovk', `${image_url}${formData.rhpp_file.delivery_order_ovk}`)"
                  >
                </div>
              </div>
            </template>
            <div
              v-else
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top uk-flex uk-flex-between"
            >
              <span class="label-grey">Tidak ada data</span>
            </div>
          </div>
          <div class="uk-grid uk-grid-medium">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Surat Jalan Pakan
            </div>
            <template v-if="formData.rhpp_file.delivery_order_feed">
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top uk-flex uk-flex-between">
                <span class="truncate">{{ formData.rhpp_file.delivery_order_feed.split('/')[3] }}</span>
                <div class="uk-flex">
                  <img
                    v-lazy="`${images}/icon/eye.svg`"
                    alt=""
                    class="uk-margin-small-right img-click"
                    @click="handlePreviewImage('pakan', `${image_url}${formData.rhpp_file.delivery_order_feed}`)"
                  >
                  <img
                    v-lazy="`${images}/icon/export.svg`"
                    alt=""
                    class="uk-margin-small-right img-click"
                    @click="handleDownloadPdf('pakan', `${image_url}${formData.rhpp_file.delivery_order_feed}`)"
                  >
                </div>
              </div>
            </template>
            <div
              v-else
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top uk-flex uk-flex-between"
            >
              <span class="label-grey">Tidak ada data</span>
            </div>
          </div>
          <div class="uk-grid uk-grid-medium">
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Data Timbang
            </div>
            <template v-if="formData.rhpp_file.weighing_data">
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top uk-flex uk-flex-between">
                <span class="truncate">{{ formData.rhpp_file.weighing_data.split('/')[3] }}</span>
                <div class="uk-flex">
                  <img
                    v-lazy="`${images}/icon/eye.svg`"
                    alt=""
                    class="uk-margin-small-right img-click"
                    @click="handlePreviewImage('pakan', `${image_url}${formData.rhpp_file.weighing_data}`)"
                  >
                  <img
                    v-lazy="`${images}/icon/export.svg`"
                    alt=""
                    class="uk-margin-small-right img-click"
                    @click="handleDownloadPdf('pakan', `${image_url}${formData.rhpp_file.weighing_data}`)"
                  >
                </div>
              </div>
            </template>
            <div
              v-else
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top uk-flex uk-flex-between"
            >
              <span class="label-grey">Tidak ada data</span>
            </div>
          </div>
        </div>

        <!-- Section Card Laba -->

        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl card-laba">
          <!-- Section Card Laba Hasil Produksi -->

          <div class="uk-margin-bottom">
            <h1 class="text-medium">
              Laba Hasil Produksi
            </h1>
            <div class="uk-margin-top wrapper-content">
              <div
                class="uk-child-width-expand@s uk-grid-small wrapper-field"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                  Total Hasil Produksi
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                  <div 
                    class="uk-input currency-field uk-text-bold"
                    :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.total_production)"
                  >
                    Rp
                    <input
                      v-model="formData.rhpp_production_profit_sharing.total_production"
                      class="uk-input"
                      :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.total_production)"
                      type="text"
                      disabled
                    >
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small wrapper-field"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                  Total Biaya Sapronak
                </div>
                <div class="uk-width-3-5 set-style">
                  <div class="uk-input currency-field label-red uk-text-bold">
                    <span>- Rp</span>
                    <input
                      v-model="formData.rhpp_production_profit_sharing.total_cost_of_sapronak"
                      class="uk-width-2-3 uk-input label-red uk-text-bold"
                      type="text"
                      disabled="disabling"
                    >
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small wrapper-field"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                  Total Retur Barang
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                  <div 
                    class="uk-input currency-field uk-text-bold"
                    :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.total_return_of_goods)"
                  >
                    Rp
                    <input
                      v-model="formData.rhpp_production_profit_sharing.total_return_of_goods"
                      class="uk-input"
                      :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.total_return_of_goods)"
                      type="text"
                      disabled
                    >
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small wrapper-field"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                  Total Biaya Operasional (Biops)
                </div>
                <div class="uk-width-3-5 set-style">
                  <div class="uk-input currency-field label-red uk-text-bold">
                    <span>- Rp</span>
                    <input
                      v-model="formData.rhpp_production_profit_sharing.total_operational_cost"
                      class="uk-width-2-3 uk-input label-red uk-text-bold"
                      type="text"
                      disabled="disabling"
                    >
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small bottom-field"
                uk-grid
              >
                <div
                  class="gr-txt uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style"
                >
                  Laba Hasil Produksi
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                  <div 
                    class="uk-input currency-field uk-text-bold set-bottom-style"
                    :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.total_production_profit)"
                  >
                    Rp
                    <input
                      v-model="formData.rhpp_production_profit_sharing.total_production_profit"
                      :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.total_production_profit)"
                      class="uk-input"
                      type="text"
                      disabled
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Section Card Perhitungan Bagi Hasil -->

          <div>
            <h1 class="text-medium">
              Perhitungan Bagi Hasil
            </h1>
            <!-- <div
                class="uk-child-width-expand@s uk-grid-small wrapper-field"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                  Subsidi
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                  <div class="uk-input currency-field">
                    Rp
                    <input
                      v-model="formData.rhpp_production_profit_sharing.subsidy"
                      class="uk-input uk-text-bold"
                      type="text"
                      disabled="disabling"
                    >
                  </div>
                </div>
              </div> -->
            <!-- card start -->
            <div class="uk-margin-top wrapper-content">
              <div
                class="uk-child-width-expand@s uk-grid-small wrapper-field"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                  Perhitungan Bagi Hasil Mitra ({{ formData.rhpp_production_profit_sharing.sharing_percentage ? formData.rhpp_production_profit_sharing.sharing_percentage : 60 }}%)
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                  <div 
                    class="uk-input currency-field uk-text-bold uk-text-bold"
                    :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.calculation_profit_sharing_partner)"
                  >
                    Rp
                    <input
                      v-model="formData.rhpp_production_profit_sharing.calculation_profit_sharing_partner"
                      class="uk-input"
                      :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.calculation_profit_sharing_partner)"
                      type="text"
                      disabled
                    >
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small wrapper-field"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                  Subsidi Asisten Mitra
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                  <div 
                    class="uk-input currency-field uk-text-bold"
                    :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.partner_assistant_subsidy)"
                  >
                    Rp
                    <input
                      v-model="formData.rhpp_production_profit_sharing.partner_assistant_subsidy"
                      class="uk-input"
                      :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.partner_assistant_subsidy)"
                      type="text"
                      disabled
                    >
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small wrapper-field"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                  Subsidi Biaya Mitra
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                  <div 
                    class="uk-input currency-field uk-text-bold"
                    :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.partner_cost_subsidy)"
                  >
                    Rp
                    <input
                      v-model="formData.rhpp_production_profit_sharing.partner_cost_subsidy"
                      class="uk-input"
                      :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.partner_cost_subsidy)"
                      type="text"
                      disabled
                    >
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small bottom-field"
                uk-grid
              >
                <div
                  class="gr-txt uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style"
                >
                  Bagi Hasil Mitra + Subsidi Biaya
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                  <div 
                    class="uk-input currency-field uk-text-bold set-bottom-style uk-text-bold"
                    :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.partner_profit_sharing_plus_subsidy_cost)"
                  >
                    Rp
                    <input
                      v-model="formData.rhpp_production_profit_sharing.partner_profit_sharing_plus_subsidy_cost"
                      :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.partner_profit_sharing_plus_subsidy_cost)"
                      class="uk-input"
                      type="text"
                      disabled
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- card start -->
            <div class="uk-margin-top wrapper-content">
              <div
                class="uk-child-width-expand@s uk-grid-small wrapper-field"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                  Pengembalian Biaya Operasional <br>(PBO)
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                  <div 
                    class="uk-input currency-field uk-text-bold"
                    :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.operational_cost_refund)"
                  >
                    Rp
                    <input
                      v-model="formData.rhpp_production_profit_sharing.operational_cost_refund"
                      class="uk-input"
                      :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.operational_cost_refund)"
                      type="text"
                      disabled
                    >
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small wrapper-field"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                  Pendapatan Kotor Mitra
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                  <div
                    class="uk-input currency-field uk-text-bold"
                    :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.gross_income_partner)"
                  >
                    Rp
                    <input
                      v-model="formData.rhpp_production_profit_sharing.gross_income_partner"
                      class="uk-input"
                      :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.gross_income_partner)"
                      type="text"
                      disabled
                    >
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small wrapper-field"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                  PBO + Pendapatan Mitra Kotor
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                  <div
                    class="uk-input currency-field uk-text-bold"
                    :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.ocr_plus_gip)"
                  >
                    Rp
                    <input
                      v-model="formData.rhpp_production_profit_sharing.ocr_plus_gip"
                      :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.ocr_plus_gip)"
                      class="uk-input"
                      type="text"
                      disabled
                    >
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small wrapper-field"
                uk-grid
              >
                <div class="uk-width-2-5 field-style">
                  Pajak Penghasilan PPH {{ formData.rhpp_production_profit_sharing.income_tax_farm_label }} ({{ formData.rhpp_production_profit_sharing.income_tax_farm }}%)
                </div>
                <div class="uk-width-3-5 set-style">
                  <div class="uk-input currency-field uk-text-bold label-red uk-text-bold">
                    <span>- Rp</span>
                    <input
                      v-model="formData.rhpp_production_profit_sharing.income_tax"
                      class="uk-width-2-3 uk-input label-red uk-text-bold"
                      type="text"
                      disabled="disabling"
                    >
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small wrapper-field"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                  Cicilan Dana Jaminan
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                  <div
                    class="uk-input currency-field uk-text-bold"
                    :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.installment_amount)"
                  >
                    Rp
                    <input
                      v-model="formData.rhpp_production_profit_sharing.installment_amount"
                      :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.installment_amount)"
                      class="uk-input"
                      type="text"
                      disabled
                    >
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small wrapper-field"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style">
                  Penyelesaian RHPP Sebelumnya
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                  <div class="uk-input currency-field uk-text-bold">
                    <div class="uk-inline">
                      <span
                        :class="{
                          'uk-form-icon': true,
                          'uk-text-bold': true,
                          'label-green': formData.rhpp.seperator_previous_rhpp_solution == 1,
                          'label-red': formData.rhpp.seperator_previous_rhpp_solution == 0
                        }"
                      >
                        {{ formData.rhpp.seperator_previous_rhpp_solution == 1 ? '+ Rp' : '- Rp' }}
                      </span>
                      <input
                        v-model="formData.rhpp_production_profit_sharing.previous_rhpp_solution"
                        :class="{
                          'uk-width-2-3': true,
                          'uk-input': true,
                          'uk-text-bold': true,
                          'label-green': formData.rhpp.seperator_previous_rhpp_solution == 1,
                          'label-red': formData.rhpp.seperator_previous_rhpp_solution == 0
                        }"
                        type="text"
                        disabled
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="border-bottom: 1px solid #C2D6CE;"
                class="uk-child-width-expand@s uk-grid-small bottom-field"
                uk-grid
              >
                <div
                  class="gr-txt uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style"
                >
                  Pendapatan Mitra Setelah Pajak
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                  <div
                    class="uk-input currency-field uk-text-bold set-bottom-style"
                    :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.partner_net_income)"
                  >
                    Rp
                    <input
                      v-model="formData.rhpp_production_profit_sharing.partner_net_income"
                      :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.partner_net_income)"
                      class="gr-txt uk-input"
                      type="text"
                      disabled
                    >
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small bottom-field"
                uk-grid
              >
                <div
                  class="gr-txt uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl field-style"
                >
                  Pendapatan Mitra Setelah Pajak per ekor
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-margin-remove-top set-style">
                  <div
                    class="uk-input currency-field uk-text-bold set-bottom-style"
                    :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.gross_income_per_head)"
                  >
                    Rp
                    <input
                      v-model="formData.rhpp_production_profit_sharing.gross_income_per_head"
                      :class="setLabelColor(detailRhpp.data.rhpp_production_profit_sharing.gross_income_per_head)"
                      class="gr-txt uk-input"
                      type="text"
                      disabled
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Section Response Pop-up Preview Document -->
    <modal-preview-image :blob="blob" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import { thousandSeparator, float0Decimals } from '@/utils/formater'
import LoadingCard from '@/components/globals/loading'
import ModalPreviewImage from '.././ModalPreviewPDF'

export default {
  components: {
    ModalPreviewImage,
    LoadingCard
  },
  props: {
    disabling: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      image_url: process.env.VUE_APP_IMAGE_URL,
      isLoading: true,
      dateJoin: new Date(),
      formatDate: 'DD/MM/YYYY',
      formData: {
        rhpp: {
          number: '',
          partner_name: ''
        },
        rhpp_production_profit_sharing: {},
        rhpp_file: {
          delivery_order_doc: '',
          delivery_order_feed: '',
          delivery_order_ovk: '',
          weighing_data: ''
        },
        status: 'true',
        city_id: ''
      },
      codeChoose: '',
      cycle_code_list: [],
      blob: {},
      docLetter: {}
    }
  },
  computed: {
    ...mapGetters({
      detailRhpp: 'rhpp/detail'
    })
  },
  watch: {
    detailRhpp() {
      if (this.detailRhpp) {
        if (this.detailRhpp.data) {
          this.isLoading = false
          const dataRhpp = this.detailRhpp.data
          const dataRhppProfitSharing = this.detailRhpp.data.rhpp_production_profit_sharing
          this.formData = {
            ...dataRhpp,
            rhpp: {
              ...dataRhpp.rhpp,
              guarantee_fund: dataRhpp.rhpp.guarantee_fund == 0 ? 'Tidak Ada' : `${dataRhpp.rhpp.guarantee_fund}%`
            },
            rhpp_production_profit_sharing: {
              ...dataRhppProfitSharing,
              total_production: thousandSeparator(dataRhppProfitSharing.total_production),
              total_cost_of_sapronak: thousandSeparator(dataRhppProfitSharing.total_cost_of_sapronak),
              total_return_of_goods: thousandSeparator(dataRhppProfitSharing.total_return_of_goods),
              total_production_profit: thousandSeparator(dataRhppProfitSharing.total_production_profit),
              gross_income: thousandSeparator(float0Decimals(dataRhppProfitSharing.gross_income)),
              income_tax: thousandSeparator(float0Decimals(dataRhppProfitSharing.income_tax)),
              installment_amount: thousandSeparator(dataRhppProfitSharing.installment_amount),
              previous_rhpp_solution: thousandSeparator(dataRhppProfitSharing.previous_rhpp_solution),
              partner_net_income: thousandSeparator(float0Decimals(dataRhppProfitSharing.partner_net_income)),
              gross_income_per_head: thousandSeparator(float0Decimals(dataRhppProfitSharing.gross_income_per_head)),
              subsidy: thousandSeparator(dataRhppProfitSharing.subsidy),
              total_operational_cost: thousandSeparator(dataRhppProfitSharing.total_operational_cost),
              calculation_profit_sharing_partner: thousandSeparator(dataRhppProfitSharing.calculation_profit_sharing_partner),
              partner_assistant_subsidy: thousandSeparator(dataRhppProfitSharing.partner_assistant_subsidy),
              partner_cost_subsidy: thousandSeparator(dataRhppProfitSharing.partner_cost_subsidy),
              partner_profit_sharing_plus_subsidy_cost: thousandSeparator(dataRhppProfitSharing.partner_profit_sharing_plus_subsidy_cost),
              operational_cost_refund: thousandSeparator(dataRhppProfitSharing.operational_cost_refund),
              gross_income_partner: thousandSeparator(dataRhppProfitSharing.gross_income_partner),
              ocr_plus_gip: thousandSeparator(dataRhppProfitSharing.ocr_plus_gip)
            }
          }
        }
      }
      this.isLoading = false
    }
  },
  methods: {
    setLabelColor(field){
      return field < 0 ? 're-text' : 'gr-text'
    },
    handleDownloadPdf(target, data) {
      const getNameFile = data.split('/')
      const nameFile = getNameFile[getNameFile.length - 1]
      const link = document.createElement('a')
      link.href = data
      link.setAttribute('download', nameFile)
      link.click()
    },
    handlePreviewImage(target, data) {
      if (target == 'doc') {
        this.blob = {
          name: 'Surat Jalan DOC',
          file: this.docLetter.doc ? URL.createObjectURL(this.docLetter.doc) : data
        }
      } else if (target == 'ovk') {
        this.blob = {
          name: 'Surat Jalan OVK',
          file: this.docLetter.ovk ? URL.createObjectURL(this.docLetter.ovk) : data
        }
      } else if (target == 'pakan') {
        this.blob = {
          name: 'Surat Jalan PAKAN',
          file: this.docLetter.pakan ? URL.createObjectURL(this.docLetter.pakan) : data
        }
      } else {
        this.blob = {
          name: 'Data Timbang', 
          file: this.docLetter.timbang ? URL.createObjectURL(this.docLetter.timbang) : data
        }
      }
      return window.UIkit.modal('#modal-preview-image').show()
    }
  }
}
</script>

<style scoped>
.gr-txt{
  color: #025231 !important;
}

.re-text{
  color: #F92626 !important;
}
h1 {
  color: #1F2E28;
  font-size: 14px;
}

.card-laba .wrapper-content {
  border: 1px solid #C2D6CE;
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
}

.card-laba .field-style {
  display: flex;
  align-items: center;
  padding-left: 16px;
  color: #1F2E28;
  font-size: 14px !important;
}

.card-laba .uk-input {
  color: #1F2E28;
  border: none;
  background: none;
}

.currency-field {
  overflow: hidden;
  display: flex;
  align-items: center;
  color: #1F2E28;
}

.wrapper-field {
  height: 58px !important;
  margin-top: 0px;
  margin-left: 0;
  border-bottom: 1px solid #c2d6ce73;
}

.bottom-field {
  height: 58px !important;
  margin-top: 0px;
  margin-left: 0;
  background: #F0F5F3;
}

.set-style {
  display: flex;
  align-items: center;
}

.set-bottom-style {
  color: #025231;
}
</style>
